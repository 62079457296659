import React, { useState } from "react";
import {
  AllCourseMainContainer,
  DropDownContainer,
  CustomSelect,
  CustomMenuItem,
  CardContainer,
  CustomCard,
  MainDropDown,
  CardImage,
  CardCourseHeading,
  IconTextContainer,
  IconContainer,
  TextContainer,
  CardCourseDescription,
  DownSection,
  CourseCardLearnMoreButton,
  CustomDropDownFormControl,
} from "../../../styles/ExploreCourseStyling/allCourseSection/styleAllCourseSection";

import { InputLabel } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useGetCourses } from "../../../customhooks/Courses/useCourses";
import CircularProgress from "@mui/material/CircularProgress";
const AllCourseSection = () => {
  const navigate = useNavigate();
  const { Course, isLoading } = useGetCourses();
  const [categoryValue, setCategoryValue] = useState("");
  const [levelValue, setLevelValue] = useState("");
  const handleCourseChange = (event) => {
    setCategoryValue(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevelValue(event.target.value.toLowerCase()); // Convert to lowercase
  };

  const filteredData = Course.filter(
    (item) =>
      (categoryValue === "" ||
        item.courseCategory?.name.toLowerCase() === categoryValue) &&
      (levelValue === "" || item?.level.toLowerCase() === levelValue)
  );

  // const filteredData = Course.filter(
  //   (item) =>
  //     (categoryValue === "" ||
  //       item.coursecategory.toLowerCase() === categoryValue.toLowerCase()) &&
  //     (levelValue === "" ||
  //       item?.level.toLowerCase() === levelValue.toLowerCase())
  // );

  // const filteredData = Course.filter(
  //   (item) =>
  //     courseValue === "" ||
  //     (item?.coursename === courseValue &&
  //       (levelValue === "" ||
  //         item.level.toLowerCase() === levelValue.toLowerCase()))
  // );

  // const filteredData = Course.filter((item) => {
  //   const isCourseMatched =
  //     categoryValue === "" || item?.category === categoryValue;
  //   const isLevelMatched =
  //     levelValue === "" ||
  //     item.level.toLowerCase() === levelValue.toLowerCase();

  //   return isCourseMatched || isLevelMatched;
  // });

  // const filteredData = Course.filter((item) => {
  //   const isCourseMatched =
  //     categoryValue === "" || item?.category.includes(categoryValue);
  //   const isLevelMatched =
  //     levelValue === "" ||
  //     item?.level.toLowerCase() === levelValue.toLowerCase();

  //   return isCourseMatched || isLevelMatched;
  // });

  // console.log("Filtered Data", filteredData);
  // console.log("level Value", levelValue);
  // console.log("Course Value", categoryValue);

  const clearFilter = () => {
    setCategoryValue("");
    setLevelValue("");
  };

  const handleCardClick = (id) => {
    navigate(`/course-details/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <AllCourseMainContainer>
      <MainDropDown>
        <DropDownContainer>
          <CustomDropDownFormControl>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ marginTop: "-5px" }}
            >
              Select Category
            </InputLabel>
            <CustomSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categoryValue}
              label="Select Course"
              onChange={handleCourseChange}
            >
              <CustomMenuItem value="science">Science</CustomMenuItem>
              <CustomMenuItem value="technology">Technology</CustomMenuItem>
              <CustomMenuItem value="engineering">Engineering</CustomMenuItem>
              <CustomMenuItem value="arts">Arts</CustomMenuItem>
              <CustomMenuItem value="maths">Maths</CustomMenuItem>
            </CustomSelect>
          </CustomDropDownFormControl>

          <CustomDropDownFormControl>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ marginTop: "-5px" }}
            >
              Select Level
            </InputLabel>
            <CustomSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={levelValue}
              label="Select Category"
              onChange={handleLevelChange}
            >
              <CustomMenuItem value="beginner">Beginner</CustomMenuItem>
              <CustomMenuItem value="intermediate">Intermediate</CustomMenuItem>
              <CustomMenuItem value="advance">Advanced</CustomMenuItem>
            </CustomSelect>
          </CustomDropDownFormControl>
          <button onClick={clearFilter} style={{ width: "120px" }}>
            Clear Filter
          </button>
        </DropDownContainer>
      </MainDropDown>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <CardContainer>
          {filteredData.length === 0 ? ( // If filteredData is empty
            <p>No courses match the selected criteria.</p>
          ) : (
            filteredData.map((course) => (
              <CustomCard
                key={course?.courseId}
                onClick={() => handleCardClick(course?.courseId)}
              >
                <CardImage>
                  <img
                    src={course?.homecourse}
                    alt={course?.course + " image"}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </CardImage>
                <DownSection>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      // alignItems: "center",
                    }}
                  >
                    <CardCourseHeading>{course?.coursename}</CardCourseHeading>
                    <div style={{ display: "flex", marginTop: "4px" }}>
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontSize: "11px",
                          fontWeight: 300,
                          color: "var(--clr-card-text)",
                        }}
                      >
                        {course?.courseCategory?.name}
                      </p>
                      /
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontSize: "11px",
                          fontWeight: 300,
                          color: "var(--clr-card-text)",
                        }}
                      >
                        {course?.level}
                      </p>
                    </div>
                  </div>
                  {course?.homepointdescription
                    .slice(0, 3)
                    .map((desc, index) => (
                      <IconTextContainer key={index}>
                        <IconContainer>
                          <CheckCircleIcon
                            style={{ color: "var(--clr-secondary)" }}
                          />
                        </IconContainer>
                        <TextContainer>
                          <CardCourseDescription>{desc}</CardCourseDescription>
                        </TextContainer>
                      </IconTextContainer>
                    ))}
                  <CourseCardLearnMoreButton>
                    Learn More
                  </CourseCardLearnMoreButton>
                </DownSection>
              </CustomCard>
            ))
          )}
        </CardContainer>
      )}
    </AllCourseMainContainer>
  );
};

export default AllCourseSection;
