import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const TotalPaymentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 20px 20px 0px",
  padding: "14px 40px 0px 40px",
  background: "var(--clr-white)",
  boxShadow: "0px 20px 30px rgba(102, 106, 245, 0.13)",
  borderRadius: 14,
  height: "380px",
}));

export const LogoTextIconContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  background: "var(--clr-white)",
}));

export const LogoTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  background: "var(--clr-white)",
}));

export const PaymentLogoContainer = styled(Box)(() => ({
  display: "flex",
  padding: "7px",
  objectFit: "contain",
}));

export const PaymentRightTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "0px 10px",
}));

export const PaymentRightHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter",
  fontSize: "22px",
  color: "var(--clr-black)",
}));
export const PaymentRightSubHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter",
  fontSize: "16px",
  color: "var(--clr-black)",
}));

export const PaymentRightIconContainer = styled(Box)(() => ({
  display: "flex",
}));

export const PaymentRightDownSection = styled(Box)(() => ({
  width: "100%",
  padding: "20px 0px",
  background: "var(--clr-white)",
}));

export const PaymentDownLeftRightContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "10px 0px",
  background: "var(--clr-white)",
}));

export const PaymentDownSectionLeftSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "45%",
  padding: "5px 12px",
  background: "var(--clr-white)",
  color: "#3e3e3e",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 300,
}));

export const PaymentDownSectionRightSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "45%",
  padding: "5px 0px",
  background: "var(--clr-white)",
  color: "#3e3e3e",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
}));

export const PaymentSummaryLeftSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "45%",
  padding: "5px 12px",
  background: "var(--clr-white)",
  color: "#3e3e3e",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 700,
}));

export const PaymentSummaryRightSide = styled(Box)(() => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "space-between",
  width: "45%",
  padding: "5px 0px",
  background: "var(--clr-white)",
  color: "#3e3e3e",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 700,
}));

export const PaymentProceedButton = styled(Box)(() => ({
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "10px 0px",
  background: "var(--clr-secondary)",
  color: "var(--clr-white)",
  fontFamily: "Inter",
  fontSize: "14px",
  borderRadius: "3px",
  fontWeight: 300,
}));
