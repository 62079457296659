import React from "react";
import {
  AmbassadorsImageContainer,
  AmbassadorsLeftSide,
  AmbassadorsLeftSideContainer,
  AmbassadorsLeftSideMainHeding,
  AmbassadorsLeftSideSubHeding,
  AmbassadorsMainContainer,
} from "../../../styles/SteamAmbassadorsStyling/ambassadorsherosection/styleAmbassadorsHero";
import { useGetAmbassador } from "../../../customhooks/ambassador/useAmbassador";
import { CircularProgress } from "@mui/material";
const AmbassadorsHeroSection = () => {
  const { TopBannerAmbassadorData, isLoading, isSuccess } = useGetAmbassador();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <AmbassadorsMainContainer>
          {TopBannerAmbassadorData.map((topdata) => (
            <React.Fragment key={topdata.TopBannerId}>
              <AmbassadorsLeftSide>
                <AmbassadorsLeftSideContainer>
                  <AmbassadorsLeftSideMainHeding>
                    STEAM Ambassadors
                  </AmbassadorsLeftSideMainHeding>
                  <AmbassadorsLeftSideSubHeding>
                    {topdata?.TopBannerDescription}
                  </AmbassadorsLeftSideSubHeding>
                </AmbassadorsLeftSideContainer>
              </AmbassadorsLeftSide>
              <AmbassadorsImageContainer>
                <img
                  src={topdata?.TopBannerImage}
                  alt="steam"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </AmbassadorsImageContainer>
            </React.Fragment>
          ))}
        </AmbassadorsMainContainer>
      </>
    );
  }
};

export default AmbassadorsHeroSection;
