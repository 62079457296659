import React from "react";
import {
  CustomGoogleMapImage,
  CustomSubText,
  LearningMainContainer,
  LearningMainHeading,
  LearningTextContainer,
  SubTextContainer,
} from "../../../styles/HomeStyling/joinlearningcommunity/styleLearningCommunity";

const JoinLearningCommunity = () => {
  return (
    <>
      <LearningMainContainer>
        <LearningTextContainer>
          <LearningMainHeading>
            Join STEAM Community of{" "}
            <span style={{ color: "var(--clr-secondary)" }}>Learning</span>
          </LearningMainHeading>
          <SubTextContainer>
            <CustomSubText>
              {" "}
              We unlock your child's potential with our programs for building a
              brighter tomorrow.
            </CustomSubText>
          </SubTextContainer>
          <CustomGoogleMapImage></CustomGoogleMapImage>
        </LearningTextContainer>
      </LearningMainContainer>
    </>
  );
};

export default JoinLearningCommunity;
