import React from "react";

import {
  CustomModal,
  CustomPaper,
  InfoSection,
  LanguageDropDownContainer,
  LeftSection,
  LogoContainer,
  MainContainer,
  RightSection,
  SupportTextContainer,
  StyledSelect,
  RightMainHeadingContainer,
  MainHeadingText,
  RightSubText,
  FormContainer,
  CustomTextField,
  FormLabel,
  RememberMeContainer,
  RememberMeText,
  CustomCheckBox,
  ForgotPassword,
  PrivacyContainer,
  CreateAccountButton,
  // SignInGoogleButton,
  // GoogleSmallImage,
  // SignINWithGoogle,
  AlreadyAccountContainer,
  AlreadyAccountText,
  SupportText,
  MainCarouselContainer,
  // SmallTopBar,
  RightTopSection,
  FormBottomSection,
  CustomPhoneInput,
} from "../../../styles/AuthenticationStyling/signup/signupStyle";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Grid, MenuItem } from "@mui/material";
// import googleImage from "../../../assets/images/google.png";
import AuthenticationSlider from "../../rightsidecarousel/AuthenticationSlider";
import SignIn from "../../AuthenticationComponents/signin/SignIn";
import "react-phone-input-2/lib/style.css";
import { useSignUp } from "../../../customhooks/Auth/useAuth";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "../../../styles/GlobalsStyling/errormessage/styleErrorMessage";
// import MobileSignUp from "../../AuthenticationComponents/signup/MobileSignUp";

const SignUp = React.memo(({ open, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const {
    selectedOption,
    loginBtnModal,
    setLoginBtnModal,
    onSubmit,
    handleOptionChange,
    handleLoginText,
  } = useSignUp();

  const handleClose = () => {
    setLoginBtnModal(false);
    onClose();
  };

  return (
    <>
      {loginBtnModal ? (
        <SignIn open={open} onClose={handleClose} />
      ) : (
        <CustomModal open={open} onClose={onClose}>
          <CustomPaper>
            {/* <SmallTopBar>
              <LogoContainer></LogoContainer>
            </SmallTopBar> */}
            <MainContainer>
              <LeftSection>
                <LogoContainer></LogoContainer>
                <MainCarouselContainer>
                  <AuthenticationSlider />
                </MainCarouselContainer>
              </LeftSection>
              <RightSection>
                <InfoSection>
                  <RightTopSection>
                    <SupportTextContainer>
                      <MailOutlineOutlinedIcon
                        style={{ color: "#504298", marginRight: "8px" }}
                      />
                      <SupportTextContainer>
                        <SupportText>Support: info@steamminds.org</SupportText>
                      </SupportTextContainer>
                    </SupportTextContainer>
                    <LanguageDropDownContainer>
                      <StyledSelect
                        value={selectedOption}
                        onChange={handleOptionChange}
                        variant="standard"
                        disableUnderline
                        MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: "#FFFFFF",
                            },
                          },
                        }}
                      >
                        <MenuItem value="English">English</MenuItem>
                        {/* <MenuItem value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem> */}
                      </StyledSelect>
                    </LanguageDropDownContainer>
                  </RightTopSection>
                </InfoSection>
                <RightMainHeadingContainer>
                  <MainHeadingText>Create Account</MainHeadingText>
                  <RightSubText>
                    Register with{" "}
                    <span style={{ color: "#504298", fontWeight: "bold" }}>
                      STEAM
                    </span>{" "}
                    Minds Today!
                  </RightSubText>
                </RightMainHeadingContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormContainer>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <FormLabel>First Name</FormLabel>
                        <CustomTextField
                          {...register("firstName", {
                            required: "First name is required",
                          })}
                        />
                        {errors.firstName && (
                          <ErrorMessage>
                            {errors.firstName.message}
                          </ErrorMessage>
                        )}
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <FormLabel>Last Name</FormLabel>
                        <CustomTextField
                          {...register("lastName", {
                            required: "Last name is required",
                          })}
                        />
                        {errors.lastName && (
                          <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormLabel>Email</FormLabel>
                        <CustomTextField
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^\S+@\S+$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <ErrorMessage>{errors.email.message}</ErrorMessage>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormLabel>Phone Number</FormLabel>
                        <CustomPhoneInput
                          country={"pk"}
                          {...register("phone", {
                            required: "Phone Number is required",
                          })}
                          onChange={(value) => {
                            setValue("phone", value);
                          }}
                        />
                        {errors.phone && (
                          <ErrorMessage>{errors.phone.message}</ErrorMessage>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <FormLabel>Password</FormLabel>
                        <CustomTextField
                          type="password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        {errors.password && (
                          <ErrorMessage>{errors.password.message}</ErrorMessage>
                        )}
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <FormLabel>Confirm Password</FormLabel>
                        <CustomTextField
                          type="password"
                          {...register("cpassword", {
                            required: "Confirm password is required",
                            validate: (value) => {
                              const password = getValues("password");
                              return (
                                value === password || "Passwords do not match"
                              );
                            },
                          })}
                        />
                        {errors.confirmPassword && (
                          <ErrorMessage>
                            {errors.confirmPassword.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                  </FormContainer>
                  <FormBottomSection>
                    <RememberMeContainer>
                      <CustomCheckBox />
                      <RememberMeText>Remember Me</RememberMeText>
                    </RememberMeContainer>
                    <ForgotPassword>Forgot Password ?</ForgotPassword>
                  </FormBottomSection>
                  <FormBottomSection>
                    <PrivacyContainer>
                      <CustomCheckBox />
                      <RememberMeText>
                        I agree to all the{" "}
                        <span style={{ color: "#7054FF" }}>Terms</span> and{" "}
                        <span style={{ color: "#7054FF" }}>Privacy policy</span>
                      </RememberMeText>
                    </PrivacyContainer>
                  </FormBottomSection>
                  <FormBottomSection>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CreateAccountButton>
                          Create Account
                        </CreateAccountButton>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <SignInGoogleButton>
                          <GoogleSmallImage
                            src={googleImage}
                            alt="google sign-in"
                          />

                          <SignINWithGoogle>
                            Sign-In With Google
                          </SignINWithGoogle>
                        </SignInGoogleButton>
                      </Grid> */}
                    </Grid>
                  </FormBottomSection>
                  <AlreadyAccountContainer>
                    <AlreadyAccountText>
                      Already have an Account ?{" "}
                      <span
                        style={{ color: "#7054FF", cursor: "pointer" }}
                        onClick={handleLoginText}
                      >
                        login
                      </span>
                    </AlreadyAccountText>
                  </AlreadyAccountContainer>
                </form>
              </RightSection>
            </MainContainer>
          </CustomPaper>
        </CustomModal>
      )}
    </>
  );
});

export default SignUp;
