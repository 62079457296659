import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export const VideoMainContainer = styled(Box)(() => ({
  display: "flex",
  padding: "40px 80px",
  margin: "20px 0px",
  alignItems: "center",
  background: "var(--clr-secondary)",
  "@media (max-width: 912px)": {
    flexDirection: "column",
  },
  "@media (max-width: 540px)": {
    padding: "20px",
  },
}));

export const VideoLeftSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
}));

export const VideoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 40px",
  justifyContent: "center",
  width: "450px",
  "@media (max-width: 540px)": {
    width: "100%",
  },
}));
export const CustomVideo = styled("video")(() => ({
  width: "380px",
  "@media (max-width: 540px)": {
    width: "300px",
  },
}));

export const VideoButton = styled(Box)(() => ({
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Roboto",
  borderRadius: "32px",
  padding: "3px 10px",
}));

export const VideoRightSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "@media (max-width: 912px)": {
    marginTop: "50px",
  },
}));

export const RightTopTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const RightMainTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
export const RightMainText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "65px",
  color: "var(--clr-white)",
  marginBottom: "20px",
  width: "100%",
  "@media (max-width: 912px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "30px",
    lineHeight: "45px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
    lineHeight: "35px",
  },
}));
export const VideoIconTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginBottom: "20px",
}));

export const CustomCircleIcon = styled(CheckCircleIcon)(() => ({
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  // backgroundColor: "var(--clr-white)",
  // width: "70px",
  // height: "45px",
  // borderRadius: "50px",
  // marginRight: "5px",
  color: "var(--clr-white)",
  width: "35px",
  height: "35px",
  "@media (max-width: 540px)": {
    width: "25px",
    height: "25px",
    marginRight: "10px",
  },
}));
export const VideoTextContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  marginLeft: "25px",
  "@media (max-width: 768px)": {
    marginLeft: "10px",
  },
}));

export const VideoSmallText = styled(Typography)(() => ({
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "18px",
  width: "100%",
  // textAlign: "justify",
  // width: "500px",
  color: "var(--clr-white)",
  // "@media (max-width: 1200px)": {
  //   width: "400px",
  // },
  // "@media (max-width: 912px)": {
  //   width: "550px",
  // },
  // "@media (max-width: 768px)": {
  //   width: "450px",
  // },
  // "@media (max-width: 550px)": {
  //   width: "300px",
  //   fontSize: "13px",
  //   lineHeight: "17px",
  // },
  // "@media (max-width: 440px)": {
  //   width: "280px",
  //   fontSize: "13px",
  //   lineHeight: "17px",
  // },
}));
