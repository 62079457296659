import React, { useState } from "react";
import {
  AuthButton,
  AuthButtonContainer,
  MobileIconContainer,
  MobileIconLinkContainer,
  MobileLinkContainer,
  MobileLinks,
  MobileSideBarContainer,
  MobileSideBarLinksContainer,
} from "../../../styles/GlobalsStyling/appbar/mobilesidebar/styleMobileSideBar";
import { NavbarLinks } from "../../../dummydata/DummyData";
import { Divider } from "@mui/material";
import SignIn from "../../AuthenticationComponents/signin/SignIn";

const MobileSidebar = React.memo(({ open }) => {
  const [isSignInOpen, setIsSignInOpen] = useState(false);

  const handleSignInOpen = () => {
    setIsSignInOpen(true);
  };

  const handleSignInClose = () => {
    setIsSignInOpen(false);
  };
  return (
    <>
      <MobileSideBarContainer>
        {NavbarLinks.map((link) => (
          <MobileSideBarLinksContainer key={link.id}>
            <MobileIconLinkContainer>
              <MobileIconContainer>
                <img
                  src={link?.icon}
                  alt="Steam Mind Home"
                  style={{ width: "100%", height: "100%" }}
                />
              </MobileIconContainer>
              <MobileLinkContainer>
                <MobileLinks to={link?.path}>{link?.name}</MobileLinks>
              </MobileLinkContainer>
            </MobileIconLinkContainer>
            <Divider />
          </MobileSideBarLinksContainer>
        ))}
        <AuthButtonContainer>
          <AuthButton onClick={handleSignInOpen}>Login / SignUp</AuthButton>
        </AuthButtonContainer>
      </MobileSideBarContainer>
      {isSignInOpen && (
        <SignIn open={isSignInOpen} onClose={handleSignInClose} />
      )}
    </>
  );
});

export default MobileSidebar;
