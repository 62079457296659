import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const PaymentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "space-between",
  margin: "20px 0px",
  padding: "30px 60px",
  width: "100%",

  // background: "red",
  "@media (max-width: 820px)": {
    width: "100%",
  },
  "@media (max-width: 400px)": {
    padding: "20px 30px",
  },
}));

export const CoursePaymentLeftSide = styled(Box)(() => ({
  display: "flex",
  width: "48%",
  padding: "0px 0px 0px 0px",
}));
export const SummaryDetailContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
export const CoursePaymentHeadingSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
export const CoursePaymentRightSide = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "48%",
  padding: "0px 0px 0px 0px",
}));

export const CoursePaymentHeading = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontWeight: 300,
  color: "var(--clr-main-heading)",
}));

export const CoursePaymentDescription = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 600,
  marginTop: "8px",
  color: "var(--clr-small-main-heading)",
}));

export const PaymentInformationSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "25px",
}));
export const CardSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  width: "100%",
}));

export const CardName = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const SavedCards = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: 600,
  marginTop: "15px",
  color: "var(--clr-small-main-heading)",
}));

export const SavedCardsSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  marginTop: "24px",
}));
export const CardsSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "50%",
  padding: "40px 0px",
  background: "red",
}));
export const AddingCardSection = styled(Box)(() => ({
  flexDirection: "row",
  width: "100%",
  marginTop: "20px",
}));
export const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: "var(--clr-white)",
  marginBottom: "10px",
  width: "100%",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "20px",
  fontFamily: "Inter",
  padding: "0px 12px",
  lineHeight: "20px",
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: "var(--clr-white)",
  textAlign: "justify",
  //   fontSize: "14px",
  //   fontFamily: "Poppins",
  //   fontWeight: 400,
  //   lineHeight: "20px",
}));
export const PaymentCustomTextField = styled("input")(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "14px",
  fontFamily: "Inter",
  width: "100%",
  padding: "12px",
  border: `1px solid var(--clr-ash)`,
  lineHeight: "15px",
  marginBottom: "20px",
  borderRadius: "5px",
}));

export const CardCvvExpiryContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  // backgroundColor: "var(--clr-white)",
  // color: "var(--clr-black)",
  // fontSize: "14px",
  // fontFamily: "Inter",
  // width: "100%",
  // padding: "12px",
  // border: "1px solid #F5F5F5",
  // lineHeight: "15px",
  // marginBottom: "20px",
}));
export const ExpiryCvvInput = styled("input")(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "14px",
  fontFamily: "Inter",
  width: "45%",
  padding: "12px",
  border: `1px solid var(--clr-ash)`,
  lineHeight: "15px",
  marginBottom: "20px",
  borderRadius: "5px",
}));
export const AddingCardButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid var(--clr-ash)`,
  color: "var(--clr-secondary)",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  padding: "12px",
  borderRadius: "5px",
  cursor: "pointer",
  "&:hover": {
    background: "var(--clr-secondary)",
    color: "var(--clr-white)",
  },
}));
export const DifferentPaymentCardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  background: "var(--clr-white)",
  padding: "0px 0px 0px 0px",
}));

// Styled components for the radio group elements
export const CustomFormControl = styled(FormControl)(() => ({
  marginBottom: "15px",
}));

export const CustomFormLabel = styled(FormLabel)(() => ({
  marginBottom: "12px",
}));

export const CustomRadioGroup = styled(RadioGroup)(() => ({
  flexDirection: "column",
}));

export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  color: "red",
}));

export const PaymentDialogDetail = styled(Box)(() => ({
  width: "100%",
  marginTop: "12px",
  // height: "320px",
  // background: "var(--clr-white)",
}));

export const PaymentCourseCarousel = styled(Box)(() => ({
  width: "100%",
  margin: "10px 0px",
  padding: "20px 0px",
}));
