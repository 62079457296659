import { useMemo } from "react";
import { useGetAnnouncementQuery } from "../../features/home/homesecondbanner/announcementSlice";

export const useGetBanner = () => {
  const { data, isSuccess, isLoading } = useGetAnnouncementQuery();
  const AnnouncementInfo = useMemo(() => {
    const getAnnouncement = data || [];

    return getAnnouncement?.map((announcement) => ({
      announcementId: announcement?._id,
      announcementTitle: announcement?.title,
      announcementDescription: announcement?.description,
      announcementimage: announcement?.bannerfile,
    }));
  }, [data]);
  return {
    Announcement: AnnouncementInfo,
    isLoading,
    isSuccess,
  };
};
