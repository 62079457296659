import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { SwiperSlide } from "swiper/react";

export const TeamMemberCarouselContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 20px",
  width: "100%",
  height: "100%",
}));

export const SingleSliderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  padding: "20px",
  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.10)",
  borderRadius: 10,
  marginBottom: "60px",
}));

export const CustomSwiperSlide = styled(SwiperSlide)(() => ({
  height: "450px",
  background: "var(--clr-white)",
  padding: "16px",
  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.10)",
  borderRadius: 10,
  "@media (max-width: 1250px)": {
    height: "470px",
  },
  "@media (max-width: 912px)": {
    height: "450px",
  },
}));
export const TopImageSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
}));
export const TopImageRoundCircleContainer = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  height: "110px",
  width: "110px",
}));
export const TopImageRoundCircle = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  objectFit: "cover",
}));
export const TeamMemberImageContainer = styled(Box)(() => ({
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  objectFit: "cover",
}));
export const TeamMemberInfoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "var(--clr-white)",
  margin: "25px 0px 0px 5px",
}));

export const NameDesignationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "var(--clr-white)",
}));

export const MemberNameContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  padding: "10px",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 700,
}));

export const MemberDesignationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  padding: "10px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  marginTop: "-12px",
}));

export const MemberDescriptionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-white)",
  padding: "10px",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontWeight: 300,
  marginTop: "10px",
  // textAlign: "justify",
}));
