import { Box } from "@mui/system";
import { styled } from "@mui/system";
export const SectionMainContainer = styled(Box)(() => ({
  background: "#F8F7FE",
  padding: "10px 0px",
}));

export const TeamMemberMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "20px 40px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const TeamMemberTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const TeamMemberMainHeading = styled(Box)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media  (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
    textAlign: "center",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Box)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));
