import React from "react";
import {
  ProgramMainContainer,
  ProgramMainHeadingContainer,
  ProgramMainHeading,
  SubTextContainer,
  CustomSubText,
  ProgramContainer,
  LeftSection,
  RightSection,
  LeftImage,
  IconTextContainer,
  RightText,
  NumberContainer,
  CustomCurve,
} from "../../../styles/HomeStyling/ourprograms/ourProgramStyle";
// import { CustomCurve } from "../../styles/ourprograms/ourProgramStyle";
import { ProgrammsData } from "../../../dummydata/DummyData";

const OurPrograms = () => {
  return (
    <>
      <ProgramMainContainer>
        <ProgramMainHeadingContainer>
          <ProgramMainHeading>
            Our&nbsp;
            <span style={{ color: "var(--clr-secondary)" }}>Programs</span>
          </ProgramMainHeading>
          <CustomCurve></CustomCurve>
          <SubTextContainer>
            <CustomSubText>
              Our programs offer various opportunities
            </CustomSubText>
          </SubTextContainer>
        </ProgramMainHeadingContainer>
        {ProgrammsData.map((program, index) => (
          <ProgramContainer
            key={program.id}
            style={{
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            }}
          >
            <LeftSection>
              <LeftImage src={program?.img} />
            </LeftSection>
            <RightSection>
              <IconTextContainer
                style={{
                  flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  textAlign: index % 2 !== 0 ? "end" : null,
                }}
              >
                <NumberContainer>{program?.id}</NumberContainer>
                <RightText>{program?.desc}</RightText>
              </IconTextContainer>
            </RightSection>
          </ProgramContainer>
        ))}
      </ProgramMainContainer>
    </>
  );
};

export default OurPrograms;
