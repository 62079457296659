import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import CurveImg from "../../../../src/assets/images/curve.png";
export const ReviewsMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",

  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const ReviewsTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export const ReviewsImageTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "10px 10px",
}));
export const ReviewMainHeading = styled(Typography)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,

  lineHeight: "44px",
  color: "#213D39",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "17px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "300px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  //   marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const CustomCurve = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   marginRight: "14px",
  marginLeft: "100px",
  marginTop: "-8px",
  width: "150px",
  height: "22px",
  backgroundImage: `url(${CurveImg})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 540px)": {
    width: "70px",
    height: "15px",
    marginLeft: "40px",
  },
}));
