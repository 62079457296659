import { useState } from "react";
import {
  useLoginUserMutation,
  useRegisterUserMutation,
} from "../../features/authentication/authApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../../features/authentication/authSlice";
import Cookies from "js-cookie";
export const useSignIn = (onClose) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [selectedOption, setSelectedOption] = useState("English");
  const [registerBtnModal, setRegisterBtnModal] = useState(false);
  const [signInUser] = useLoginUserMutation();
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRegisterText = () => {
    setRegisterBtnModal(true);
  };
  const onSubmit = async (data) => {
    try {
      const res = await signInUser(data).unwrap();

      if (res.success === true) {
        dispatch(setUser(res.user));
        Cookies.set("token", res?.token);
        const userDataJSON = JSON.stringify(res.user);

        // Set the user data in a cookie
        Cookies.set("userData", userDataJSON, { expires: 7 });
        toast.success("User login Successfully");
        onClose();
        naviagte("/");
      }
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message);
      }
    }
  };

  return {
    handleOptionChange,
    handleRegisterText,
    setRegisterBtnModal,
    onSubmit,
    selectedOption,
    registerBtnModal,
  };
};

export const useSignUp = () => {
  const [selectedOption, setSelectedOption] = useState("English");
  const [loginBtnModal, setLoginBtnModal] = useState(false);
  const [phone, setPhoneNumber] = useState("");
  const [signUpUser] = useRegisterUserMutation();
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleLoginText = () => {
    setLoginBtnModal(true);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const onSubmit = async (data) => {
    try {
      const res = await signUpUser(data).unwrap();
      // console.log(res);
      if (res.success === true) {
        toast.success("Registered Successfully ");
      }
    } catch (error) {
      if (error.data) {
        toast.error(error.data.error);
      }
    }
  };
  return {
    selectedOption,
    loginBtnModal,
    phone,
    onSubmit,
    setLoginBtnModal,
    handleOptionChange,
    handleLoginText,
    handlePhoneNumberChange,
  };
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  return user;
};

export const logOutUser = (dispatch) => {
  // localStorage?.removeItem("token");
  // localStorage?.removeItem("userData");
  Cookies.remove("userData");
  Cookies.remove("token");
  dispatch(clearUser());
};
