import { useMemo } from "react";
import { useGetCategoryQuery } from "../../../features/home/category/categorySlice";

export const useGetCategory = () => {
  const { data, isSuccess, isLoading } = useGetCategoryQuery();
  const CategoryInfo = useMemo(() => {
    const getCategory = data || [];

    return getCategory?.map((category) => ({
      categoryId: category?._id,
      categoryName: category?.name,
      categoryDescription: category?.catdescription,
      categoryImage: category?.catimage,
    }));
  }, [data]);
  return {
    Category: CategoryInfo,
    isLoading,
    isSuccess,
  };
};
