import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";

export const MobileSideBarContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "24px",
}));

export const MobileSideBarLinksContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  padding: "12px 8px",
  flexDirection: "column",
  marginBottom: "12px",
}));

export const MobileIconLinkContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  padding: "3px",
}));

export const MobileIconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "14px",
  width: "20px",
  height: "20px",
}));

export const MobileLinkContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const MobileLinks = styled(NavLink)(() => ({
  display: "flex",
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  letterSpacing: "0.032em",
  color: "#000000",
}));

export const AuthButtonContainer = styled(Box)(() => ({
  width: "100%",
  display: "none",
  "@media (max-width: 540px)": {
    display: "block",
  },
}));

export const AuthButton = styled("button")(() => ({
  display: "flex",
  width: "100%",
  borderRadius: "20px",
  fontFamily: "Poppins",
  height: "40px",
  alignItems: "center",
  justifyContent: "center",
  background: "#000000",
  color: "#fff",
  border: "1px solid #fff",
  outline: "none",
  fontWeight: 500,
}));
