import React from "react";
import {
  CompetationMainContainer,
  CompetationMainHeading,
  CompetationTextContainer,
} from "../../../styles/AboutUsStyling/competation/styleOurCompetation";
import WiningCompetationSlider from "../../rightsidecarousel/WiningCompetationSlider";

const WonCompetation = () => {
  return (
    <>
      <CompetationMainContainer>
        <CompetationTextContainer>
          <CompetationMainHeading>Competition We Won</CompetationMainHeading>
        </CompetationTextContainer>
      </CompetationMainContainer>

      <WiningCompetationSlider />
    </>
  );
};

export default WonCompetation;
