import React from "react";
import {
  CustomCurve,
  ReviewMainHeading,
  ReviewsImageTextContainer,
  ReviewsMainContainer,
  ReviewsTextContainer,
  SubTextContainer,
  CustomSubText,
} from "../../../styles/HomeStyling/studentreviews/styleStudentReviews";
import StudentReviewSlider from "../../rightsidecarousel/StudentReviewSlider";

const StudentReviews = () => {
  return (
    <>
      <ReviewsMainContainer>
        <ReviewsTextContainer>
          <ReviewsImageTextContainer>
            <ReviewMainHeading>
              Real{" "}
              <span style={{ color: "var(--clr-secondary)" }}>Stories</span>,
            </ReviewMainHeading>
            <CustomCurve />
          </ReviewsImageTextContainer>

          <ReviewsImageTextContainer>
            <ReviewMainHeading>
              Real{" "}
              <span style={{ color: "var(--clr-secondary)" }}>Success</span>
            </ReviewMainHeading>
            <CustomCurve />
          </ReviewsImageTextContainer>
        </ReviewsTextContainer>
        <SubTextContainer>
          <SubTextContainer>
            <CustomSubText>See what our Students have to Say!</CustomSubText>
          </SubTextContainer>
        </SubTextContainer>
        <StudentReviewSlider />
      </ReviewsMainContainer>
    </>
  );
};

export default StudentReviews;
