import React from "react";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import ContactHeroSection from "../../components/ContactUsComponent/herosection/ContactHeroSection";
import Footer from "../../components/GlobalComponents/footer/Footer";

const ContactUs = () => {
  return (
    <>
      {" "}
      <Navbar />
      <ContactHeroSection />
      <div style={{ marginTop: "650px" }}>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
