import React from "react";
import {
  ContactFormHeadingContainer,
  ContactFormSection,
  ContactMainContainer,
  ContactMainHeading,
  ContactSubText,
  CustomFormButton,
  CustomFormInput,
  CustomFormTextArea,
  FormImageContainer,
  FormLeftContainer,
  FormRightContainer,
  FormRightImageContainer,
  ContactInfoContainer,
  ContactIconTextContainer,
  FormIconImageContainer,
  FormIconTextContainer,
  FormSocialMediaContainer,
  FormSocialIconContainer,
} from "../../../styles/ContactStyling/contactform/styleContactForm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useForm } from "react-hook-form";
import { useContactUs } from "../../../customhooks/contactus/useContacts";
import { ErrorMessage } from "../../../styles/GlobalsStyling/errormessage/styleErrorMessage";
import { useFooter } from "../../../customhooks/Navbar/Footer/useFooter";
const ContactForm = () => {
  const { handleFacebook, handleinstagram, handlelinkedin, handletwitter } =
    useFooter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { onSubmit } = useContactUs();
  return (
    <>
      <ContactFormSection>
        <ContactMainContainer>
          <ContactFormHeadingContainer>
            <ContactMainHeading>Contact Us</ContactMainHeading>
            <ContactSubText>
              We are here for you! How can we help?
            </ContactSubText>
          </ContactFormHeadingContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormImageContainer>
              <FormLeftContainer>
                <CustomFormInput
                  type="email"
                  placeholder="Enter Your Email"
                  {...register("recipient", {
                    required: "Email is required",
                  })}
                />
                {errors.recipient && (
                  <ErrorMessage>{errors.recipient.message}</ErrorMessage>
                )}
                <CustomFormInput
                  placeholder="Enter Message Subject"
                  {...register("subject", {
                    required: "Subject is required",
                  })}
                />
                {errors.subject && (
                  <ErrorMessage>{errors.subject.message}</ErrorMessage>
                )}
                <CustomFormTextArea
                  rows="4"
                  placeholder="Go a head, we are listening"
                  {...register("body", {
                    required: "Your Message is required",
                  })}
                />
                {errors.body && (
                  <ErrorMessage>{errors.body.message}</ErrorMessage>
                )}
                <CustomFormButton type="submit">Submit</CustomFormButton>
              </FormLeftContainer>

              <FormRightContainer>
                <FormRightImageContainer></FormRightImageContainer>
                <ContactInfoContainer>
                  <ContactIconTextContainer>
                    <FormIconImageContainer>
                      <LocationOnIcon sx={{ color: "var(--clr-secondary)" }} />
                    </FormIconImageContainer>
                    <FormIconTextContainer>
                      1st Floor IT Park near Missile Chowk, Mandian, Abbottabad,
                      Pakistan
                    </FormIconTextContainer>
                  </ContactIconTextContainer>
                  <ContactIconTextContainer>
                    <FormIconImageContainer>
                      <LocalPhoneOutlinedIcon
                        sx={{ color: "var(--clr-secondary)" }}
                      />
                    </FormIconImageContainer>
                    <FormIconTextContainer>
                      +92 319 0412670
                    </FormIconTextContainer>
                  </ContactIconTextContainer>
                  <ContactIconTextContainer>
                    <FormIconImageContainer>
                      <EmailOutlinedIcon
                        sx={{ color: "var(--clr-secondary)" }}
                      />
                    </FormIconImageContainer>
                    <FormIconTextContainer>
                      info@steamminds.org
                    </FormIconTextContainer>
                  </ContactIconTextContainer>
                </ContactInfoContainer>
                <FormSocialMediaContainer>
                  <FormSocialIconContainer>
                    <FacebookOutlinedIcon
                      style={{ color: "var(--clr-white)", cursor: "pointer" }}
                      onClick={handleFacebook}
                    />
                    <TwitterIcon
                      style={{ color: "var(--clr-white)", cursor: "pointer" }}
                      onClick={handletwitter}
                    />
                    <InstagramIcon
                      style={{ color: "var(--clr-white)", cursor: "pointer" }}
                      onClick={handleinstagram}
                    />
                    <LinkedInIcon
                      style={{ color: "var(--clr-white)", cursor: "pointer" }}
                      onClick={handlelinkedin}
                    />
                  </FormSocialIconContainer>
                </FormSocialMediaContainer>
              </FormRightContainer>
            </FormImageContainer>
          </form>
        </ContactMainContainer>
      </ContactFormSection>
    </>
  );
};

export default ContactForm;
