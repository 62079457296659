import React from "react";
import {
  IconContainer,
  IconTextContainer,
  IncludeHeading,
  IncludeSubDescriptionContainer,
  SingleCourseDemoButton,
  SingleCourseDownSection,
  SingleCourseEnrollButton,
  SingleCourseIncludeSection,
  SingleCourseLeftSide,
  SingleCourseLeftSideContainer,
  SingleCourseLeftSideMainHeding,
  SingleCourseLeftSideSubHeding,
  SingleCourseMainContainer,
  SingleCourseModule,
  SingleCourseModuleContainer,
  SingleCourseRightBanner,
  SingleCourseRightImage,
  TextContainer,
} from "../../../../styles/ExploreCourseStyling/singleCourse/singlecoursehero/styleSingleCourseHero";
import Navbar from "../../../GlobalComponents/appbar/Navbar";
import { BreadCrumb } from "../../../GeneralComponents/GeneralComponent";

import VideoIcon from "../../../../assets/images/videoicon.png";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SingleCourseDetailInfo from "../singlecoursedetailinfo/SingleCourseDetailInfo";
import Footer from "../../../GlobalComponents/footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetCourseByIdQuery } from "../../../../features/courses/courseSlice";
const SingleCourse = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const {
    data: SingleCourseDetail = {},
    isLoading,
    isSuccess,
  } = useGetCourseByIdQuery(id);
  const {
    name,
    shortdescription,
    longdescription,
    level,
    duration,
    numberlesson,
    homecourse,
    numbermodules,
    lesson,
    learndescription,
    course_includes,
    certificate,
  } = SingleCourseDetail?.course || {};

  const handleEnroll = (id) => {
    navigate(`/enroll-now/${id}`);
  };

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            // height: "0vh", // Set height to the full viewport height
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <SingleCourseMainContainer>
          <SingleCourseLeftSide>
            <BreadCrumb
              items={[
                { label: "Steamminds", to: "/" },
                { label: "Courses", to: "/explore-allcourses" },
                { label: name },
              ]}
            />
            <SingleCourseLeftSideContainer>
              <SingleCourseLeftSideMainHeding>
                {name}
              </SingleCourseLeftSideMainHeding>
              <SingleCourseLeftSideSubHeding>
                {shortdescription}
              </SingleCourseLeftSideSubHeding>
              <SingleCourseModuleContainer>
                <SingleCourseModule>{level}</SingleCourseModule>
                <SingleCourseModule>{duration}</SingleCourseModule>
                <SingleCourseModule>{numberlesson}</SingleCourseModule>
                <SingleCourseModule>{numbermodules}</SingleCourseModule>
              </SingleCourseModuleContainer>
            </SingleCourseLeftSideContainer>
          </SingleCourseLeftSide>
          <SingleCourseRightBanner>
            <SingleCourseRightImage>
              <img
                src={homecourse}
                alt="courseimage"
                style={{ width: "100%", height: "100%" }}
              />
            </SingleCourseRightImage>
            <SingleCourseDownSection>
              <SingleCourseDemoButton>Try Demo</SingleCourseDemoButton>
              <SingleCourseIncludeSection>
                <IncludeHeading>This Course Includes</IncludeHeading>
                <IncludeSubDescriptionContainer>
                  {course_includes?.map((includes, index) => (
                    <IconTextContainer key={index}>
                      <IconContainer>
                        <img src={VideoIcon} alt="video" />
                      </IconContainer>
                      <TextContainer>{includes}</TextContainer>
                    </IconTextContainer>
                  ))}
                  <Divider style={{ margin: "5px 0px" }} />
                </IncludeSubDescriptionContainer>
              </SingleCourseIncludeSection>
              <SingleCourseIncludeSection>
                <IncludeHeading>Certifications</IncludeHeading>
                <IncludeSubDescriptionContainer>
                  {certificate?.map((certificate, index) => (
                    <IconTextContainer key={index}>
                      <IconContainer>
                        <EmojiEventsIcon
                          style={{ color: "var(--clr-secondary)" }}
                        />
                      </IconContainer>
                      <TextContainer>{certificate}</TextContainer>
                    </IconTextContainer>
                  ))}
                  <Divider style={{ margin: "5px 0px" }} />
                </IncludeSubDescriptionContainer>
              </SingleCourseIncludeSection>
              <SingleCourseEnrollButton onClick={() => handleEnroll(id)}>
                Enroll Now
              </SingleCourseEnrollButton>
            </SingleCourseDownSection>
          </SingleCourseRightBanner>
        </SingleCourseMainContainer>
      )}
      {isSuccess && (
        <SingleCourseDetailInfo
          name={name}
          longdescription={longdescription}
          isLoading={isLoading}
          lesson={lesson}
          learndescription={learndescription}
        />
      )}

      {/* Footer */}
      <Footer />
    </>
  );
};

export default SingleCourse;
