import React from "react";
import {
  QuestionMainHeading,
  QuestionsMainContainer,
  QuestionsTextContainer,
  SubTextContainer,
  CustomSubText,
  QuestionsAccordianContainer,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "../../../styles/HomeStyling/askedquestions/styleAskedQuestions";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetFaq } from "../../../customhooks/FaqQuestion/useFaq";
const AskedQuestions = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { FAQ, isLoading } = useGetFaq();

  return (
    <>
      <QuestionsMainContainer>
        <QuestionsTextContainer>
          <QuestionMainHeading>
            Frequently Asked{" "}
            <span style={{ color: "var(--clr-secondary)" }}>Questions</span>
          </QuestionMainHeading>
          <SubTextContainer>
            <CustomSubText>Your STEAM FAQ Guide</CustomSubText>
          </SubTextContainer>
        </QuestionsTextContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <QuestionsAccordianContainer>
            {FAQ.slice(0, 6).map((item) => (
              <StyledAccordion
                key={item.questionId}
                expanded={expanded === `panel${item.questionId}`}
                onChange={handleChange(`panel${item.questionId}`)}
              >
                <StyledAccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        background: "var(--clr-secondary)",
                        borderRadius: "50px",
                        color: "var(--clr-white)",
                      }}
                     />
                  }
                >
                  {item?.question}
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <p>{item?.description}</p>
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </QuestionsAccordianContainer>
        )}
      </QuestionsMainContainer>
    </>
  );
};

export default AskedQuestions;
