import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import CurveImg from "../../../../src/assets/images/curve.png";

export const ProgramMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "30px 60px",
}));

export const ProgramMainHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px",
  width: "100%",
}));

export const ProgramMainHeading = styled(Typography)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  width: "100%",
  fontSize: "43px",
  lineHeight: "65px",
  color: "var(--clr-small-main-heading)",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "17px",
  },
}));

export const CustomCurve = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   marginRight: "14px",
  marginLeft: "40px",
  marginTop: "-12px",
  width: "190px",
  height: "22px",
  backgroundImage: `url(${CurveImg})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 760px)": {
    width: "120px",
    height: "17px",
  },
  "@media (max-width: 540px)": {
    width: "90px",
    height: "11px",
  },
  "@media (max-width: 400px)": {
    width: "110px",
    height: "7px",
    marginTop: "-10px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "300px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const ProgramContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  margin: "30px 0px",
  "@media (max-width: 912px)": {
    flexDirection: "column !important",
  },
}));

export const LeftSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "var(--clr-ash)",
  borderRadius: "28px",
}));

export const LeftImage = styled("img")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "14px",
  width: "100%",
  height: "100%",
}));

export const RightSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 912px)": {
    marginTop: "40px",
  },
}));
export const IconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  //   width: "530px",
  //   "@media (max-width: 912px)": {
  //     width: "370px",
  //   },
}));
export const NumberContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "35px",
  height: "35px",
  padding: "4px 10px",
  backgroundColor: "var(--clr-secondary)",
  color: "var(--clr-white)",
  borderRadius: "4px",
  margin: "12px",
}));
export const RightText = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "24px",
  "@media (max-width: 768px)": {
    fontSize: "20px",
  },
  "@media (max-width: 540px)": {
    fontSize: "18px",
  },
}));
