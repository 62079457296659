import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const HomeHeroContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  background: "#F8F7FE",
  "@media (max-width: 1000px)": {
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
  },

  "@media (max-width: 440px)": {
    margin: "0px",
    padding: "0px",
  },
}));

export const HeadingTextContainer = styled(Box)(() => ({
  display: "flex",
  width: "50%",
  flexDirection: "column",
  margin: "50px",
  padding: "25px",
  "@media (max-width: 1000px)": {
    width: "100%",
  },
  "@media (max-width: 440px)": {
    width: "100%",
    margin: "20px",
    padding: "10px",
  },
}));

export const HeadingText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 900,
  fontSize: "50px",
  lineHeight: "76px",
  width: "543px",
  color: "#3A3939",
  "@media (max-width: 768px)": {
    width: "490px",
    fontSize: "48px",
    lineHeight: "60px",
  },
  "@media (max-width: 540px)": {
    width: "470px",
    fontSize: "38px",
    lineHeight: "55px",
  },
  "@media (max-width: 440px)": {
    width: "340px",
    fontSize: "32px",
    lineHeight: "45px",
  },
}));

export const BodyText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "18px",
  textAlign: "justify",
  lineHeight: "28px",
  marginTop: "15px",
  color: "#5E5D5D",
  "@media (max-width: 768px)": {
    width: "490px",
    fontSize: "18px",
    lineHeight: "24px",
  },
  "@media (max-width: 540px)": {
    width: "470px",
    fontSize: "14px",
    lineHeight: "22px",
  },
  "@media (max-width: 440px)": {
    width: "330px",
    fontSize: "12px",
    lineHeight: "20px",
  },
}));

export const HeroButton = styled(Button)(() => ({
  width: "150px",
  borderRadius: "32px",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 200,
  fontSize: "16px",
  padding: "5px",
  lineHeight: "25px",
  color: "#FFFFFF",
  marginTop: "17px",
  textTransform: "capitalize",
  background: "linear-gradient(0deg, #EF0622, #EF0622), #00B8D9",
  boxShadow:
    "0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)",
  "@media (max-width: 440px)": {
    width: "170px",
    fontSize: "18px",
    lineHeight: "24px",
  },
}));
export const ProgressBannerContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  margin: "40px 0px",
  background: "#504298",
  alignItems: "center",
  padding: "12px",
  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  borderRadius: "8px",
  "@media (max-width: 768px)": {
    padding: "8px",
  },
  "@media (max-width: 540px)": {
    padding: "5px",
  },
}));
export const NameContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
}));
export const Number = styled(Typography)(() => ({
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "34px",
  "@media (max-width: 768px)": {
    fontSize: "20px",
    lineHeight: "30px",
  },
}));
export const Name = styled(Typography)(() => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 300,
  marginTop: "6px",
  fontSize: "16px",
  lineHeight: "18px",
  "@media (max-width: 768px)": {
    fontSize: "14px",
    lineHeight: "16px",
  },
}));

export const RightBorder = styled(Box)(() => ({
  width: "2px",
  height: "50px",
  borderRight: "1px solid white",
}));

export const ImageContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "8px",
  padding: "4px",
}));
