import React from "react";

import {
  CustomModal,
  CustomPaper,
  InfoSection,
  LanguageDropDownContainer,
  LeftSection,
  LogoContainer,
  MainContainer,
  RightSection,
  SupportTextContainer,
  StyledSelect,
  RightMainHeadingContainer,
  MainHeadingText,
  RightSubText,
  FormContainer,
  CustomTextField,
  FormLabel,
  FormBottomSection,
  RememberMeContainer,
  RememberMeText,
  CustomCheckBox,
  ForgotPassword,
  PrivacyContainer,
  CreateAccountButton,
  // GoogleSmallImage,
  // SignINWithGoogle,
  AlreadyAccountContainer,
  AlreadyAccountText,
  SupportText,
  MainCarouselContainer,
  // SmallTopBar,
  RightTopSection,
  SignInBottomSection,
  // RegisterGoogleButton,
} from "../../../styles/AuthenticationStyling/signup/signupStyle";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Grid, MenuItem } from "@mui/material";
// import googleImage from "../../../assets/images/google.png";
import AuthenticationSlider from "../../../components/rightsidecarousel/AuthenticationSlider";
import SignUp from "../../AuthenticationComponents/signup/SignUp";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "../../../styles/GlobalsStyling/errormessage/styleErrorMessage";
import { useSignIn } from "../../../customhooks/Auth/useAuth";

const SignIn = React.memo(({ open, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    handleOptionChange,
    handleRegisterText,
    setRegisterBtnModal,
    onSubmit,
    selectedOption,
    registerBtnModal,
  } = useSignIn(onClose);

  const handleClose = () => {
    setRegisterBtnModal(false);
    onClose();
  };

  return (
    <>
      {registerBtnModal ? (
        <SignUp open={open} onClose={handleClose} />
      ) : (
        <CustomModal open={open} onClose={onClose}>
          <CustomPaper>
            {/* <SmallTopBar>
              <LogoContainer></LogoContainer>
            </SmallTopBar> */}
            <MainContainer>
              <LeftSection>
                <LogoContainer></LogoContainer>
                <MainCarouselContainer>
                  <AuthenticationSlider />
                </MainCarouselContainer>
              </LeftSection>
              <RightSection>
                <InfoSection>
                  <RightTopSection>
                    <SupportTextContainer>
                      <MailOutlineOutlinedIcon
                        style={{ color: "#504298", marginRight: "8px" }}
                      />
                      <SupportTextContainer>
                        <SupportText>Support: info@steamminds.org</SupportText>
                      </SupportTextContainer>
                    </SupportTextContainer>
                    <LanguageDropDownContainer>
                      <StyledSelect
                        value={selectedOption}
                        onChange={handleOptionChange}
                        variant="standard"
                        disableUnderline
                        MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: "#FFFFFF",
                            },
                          },
                        }}
                      >
                        <MenuItem value="English">English</MenuItem>
                        {/* <MenuItem value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem> */}
                      </StyledSelect>
                    </LanguageDropDownContainer>
                  </RightTopSection>
                </InfoSection>
                <RightMainHeadingContainer>
                  <MainHeadingText>Welcome Back</MainHeadingText>
                  <RightSubText>Please Enter your Details</RightSubText>
                </RightMainHeadingContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomTextField
                          type="email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <ErrorMessage> {errors.email.message}</ErrorMessage>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormLabel>Password</FormLabel>
                        <CustomTextField
                          type="password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        {errors.password && (
                          <ErrorMessage>{errors.password.message}</ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                  </FormContainer>
                  <FormBottomSection>
                    <RememberMeContainer>
                      <CustomCheckBox />
                      <RememberMeText>Remember Me</RememberMeText>
                    </RememberMeContainer>
                    <ForgotPassword>Forgot Password ?</ForgotPassword>
                  </FormBottomSection>
                  <FormBottomSection>
                    <PrivacyContainer>
                      <CustomCheckBox />
                      <RememberMeText>
                        I agree to all the{" "}
                        <span style={{ color: "#7054FF" }}>Terms</span> and{" "}
                        <span style={{ color: "#7054FF" }}>Privacy policy</span>
                      </RememberMeText>
                    </PrivacyContainer>
                  </FormBottomSection>
                  <SignInBottomSection>
                    <CreateAccountButton type="submit">
                      Sign In
                    </CreateAccountButton>

                    {/* <RegisterGoogleButton>
                      <GoogleSmallImage
                        src={googleImage}
                        alt="google sign-in"
                      />

                      <SignINWithGoogle>Sign-In With Google</SignINWithGoogle>
                    </RegisterGoogleButton> */}
                  </SignInBottomSection>
                  <AlreadyAccountContainer>
                    <AlreadyAccountText>
                      Don't have an Account ?{" "}
                      <span
                        style={{ color: "#7054FF", cursor: "pointer" }}
                        onClick={handleRegisterText}
                      >
                        Sign Up
                      </span>
                    </AlreadyAccountText>
                  </AlreadyAccountContainer>
                </form>
              </RightSection>
            </MainContainer>
          </CustomPaper>
        </CustomModal>
      )}
    </>
  );
});

export default SignIn;
