import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
export const FooterMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "var(--clr-secondary)",
}));
export const LogoLinkMainContainer = styled(Box)(() => ({
  display: "flex",
  padding: "40px 80px",
  margin: "10px 0px",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "@media (max-width: 1200px)": {
    padding: "20px",
  },
  "@media (max-width: 1024px)": {
    justifyContent: "space-around",
    padding: "40px 40px",
    margin: "10px 0px",
  },
  "@media (max-width: 540px)": {
    flexDirection: "column",
    padding: "40px 10px",
    margin: "10px 0px",
  },
}));

export const LogoSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px 0px",
}));

export const BottomText = styled(Box)(() => ({
  display: "flex",
  fontSize: "11px",
  fontFamily: "DM Sans",
  marginLeft: "5px",
  fontWeight: 300,
  width: "90px",
  color: "var(--clr-white)",
  "@media (max-width: 540px)": {
    marginTop: "10px",
    marginBottom: "-5px",
  },
}));

export const SocialMediaIconContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: "12px",
  fontSize: "11px",
  fontFamily: "DM Sans",
  marginLeft: "3px",
  fontWeight: 300,
  color: "var(--clr-white)",
}));
export const FooterRightContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  "@media (max-width: 1024px)": {
    flexDirection: "column",
    justifyContent: "space-around",
  },
  "@media (max-width: 540px)": {
    flexDirection: "column",
  },
}));
export const ProductCompanyContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  width: "450px",
  "@media (max-width: 540px)": {
    width: "280px",
    justifyContent: "space-evenly",
  },
}));

export const SupportContactContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  width: "450px",
  "@media (max-width: 1024px)": {
    marginTop: "40px",
  },
  "@media (max-width: 540px)": {
    width: "280px",
    justifyContent: "space-evenly",
  },
}));
export const FooterTopHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
export const FooterTopHeading = styled(Box)(() => ({
  display: "flex",
  marginBottom: "14px",
  fontSize: "15px",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "var(--clr-white)",
  letterSpacing: "1px",
}));

export const TopHeadingItems = styled(NavLink)(() => ({
  display: "flex",
  textDecoration: "none",
  fontSize: "14px",
  marginBottom: "10px",
  fontFamily: "Poppins",
  fontWeight: 300,
  letterSpacing: "1px",
  color: "var(--clr-white)",
  cursor: "pointer",
}));

export const AddressItem = styled(Box)(() => ({
  display: "flex",
  width: "220px",
  fontSize: "14px",
  marginBottom: "10px",
  fontFamily: "DM Sans",
  fontWeight: 300,
  letterSpacing: "1px",
  lineHeight: "20px",
  color: "var(--clr-white)",
  cursor: "pointer",
  "@media (max-width: 912px)": {
    width: "140px",
  },
  "@media (max-width: 540px)": {
    width: "130px",
    lineHeight: "22px",
  },
}));

export const BottomSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px 80px",
  margin: "10px 0px",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "@media (max-width: 1200px)": {
    padding: "20px",
  },
  "@media (max-width: 1024px)": {
    justifyContent: "space-around",
    padding: "20px 40px",
    margin: "10px 0px",
  },
  "@media (max-width: 540px)": {
    flexDirection: "column",
    padding: "0px 12px",
    margin: "10px 0px",
  },
}));

export const CustomFooterDivider = styled(Divider)(() => ({
  color: "#000",
  width: "100%",
  borderColor: "var(--clr-white)",
  borderStyle: "solid",
  fontWeight: "bold",
  "@media (max-width: 540px)": {
    flexDirection: "column",
  },
}));

export const AllRightReseverdContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "10px",
}));

export const AllRightReseverd = styled(Typography)(() => ({
  fontSize: "12px",
  marginBottom: "10px",
  fontFamily: "Poppins",
  fontWeight: 300,
  letterSpacing: "1px",
  color: "var(--clr-white)",
  cursor: "pointer",
}));
