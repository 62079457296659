import React from "react";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import AmbassadorsHeroSection from "../../components/SteamAmbassadors/ambassadorsherosection/AmbassadorsHeroSection";
import STEAMAmbassadors from "../../components/SteamAmbassadors/steamambassadors/SteamAmbassadors";
import Footer from "../../components/GlobalComponents/footer/Footer";

const SteamAmbassadors = () => {
  return (
    <>
      <Navbar />
      <AmbassadorsHeroSection />
      <STEAMAmbassadors />
      <Footer />
    </>
  );
};

export default SteamAmbassadors;
