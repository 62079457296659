import React from "react";
import {
  CustomSubText,
  SingleCourseQuestionMainHeading,
  SingleCourseQuestionStyledAccordion,
  SingleCourseQuestionStyledAccordionDetails,
  SingleCourseQuestionStyledAccordionSummary,
  SingleCourseQuestionsAccordianContainer,
  SingleCourseQuestionsMainContainer,
  SingleCourseQuestionsTextContainer,
  SubTextContainer,
} from "../../../styles/ExploreCourseStyling/singlecoursequestion/styleSingleCourseQuestion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { useGetFaqByCourseIdQuery } from "../../../features/courses/coursequestion/coureFaqSlice";
const SingleCourseQuestion = () => {
  const { id } = useParams();
  const { data: courseFaq = [] } = useGetFaqByCourseIdQuery(id);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <SingleCourseQuestionsMainContainer>
        <SingleCourseQuestionsTextContainer>
          <SingleCourseQuestionMainHeading>
            Frequently Asked Questions
          </SingleCourseQuestionMainHeading>
          <SubTextContainer>
            <CustomSubText>FAQ- Python Programming</CustomSubText>
          </SubTextContainer>
        </SingleCourseQuestionsTextContainer>
        <SingleCourseQuestionsAccordianContainer>
          {courseFaq.slice(0, 5).map((FAQ) => (
            <SingleCourseQuestionStyledAccordion
              key={FAQ?.questionId}
              expanded={expanded === `panel${FAQ?.questionId}`}
              onChange={handleChange(`panel${FAQ?.questionId}`)}
            >
              <SingleCourseQuestionStyledAccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      background: "var(--clr-secondary)",
                      borderRadius: "50px",
                      color: "var(--clr-white)",
                    }}
                  />
                }
              >
                {FAQ?.question}
              </SingleCourseQuestionStyledAccordionSummary>
              <SingleCourseQuestionStyledAccordionDetails>
                {FAQ?.description}
              </SingleCourseQuestionStyledAccordionDetails>
            </SingleCourseQuestionStyledAccordion>
          ))}
        </SingleCourseQuestionsAccordianContainer>
      </SingleCourseQuestionsMainContainer>
    </>
  );
};

export default SingleCourseQuestion;
