import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const SingleCourseDetailInfoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  margin: "20px 0px",
  padding: "30px 60px",
  width: "64%",
  // background: "red",
  "@media (max-width: 820px)": {
    width: "100%",
  },
  "@media (max-width: 400px)": {
    padding: "20px 30px",
  },
}));

export const WelcomeInfoContainer = styled(Box)(() => ({
  width: "100%",
  background: "var(--clr-white)",
  boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.07)",
  borderRadius: "10px",
  padding: "12px",
  marginBottom: "25px",
}));

export const WelcomeCourseInfoHeading = styled(Box)(() => ({
  alignItems: "center",
  padding: "7px 10px",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontWeight: 700,
  lineHeight: "30px",
  color: "var(--clr-gray)",

  "@media (max-width: 912px)": {
    fontSize: "22px",
  },
}));

export const WelcomeCourseInfoDescription = styled(Box)(() => ({
  marginTop: "8px",
  alignItems: "center",
  padding: "4px 10px",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: "300",
  lineHeight: "30px",
  color: "var(--clr-gray)",
  "@media (max-width: 912px)": {
    fontSize: "17px",
  },
}));
export const CourseInfoLearnContainer = styled(Box)(() => ({
  width: "100%",
  background: "var(--clr-white)",
  boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.07)",
  borderRadius: "10px",
  padding: "12px",
  marginBottom: "25px",
}));
export const CourseInfoLearningHeading = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "7px 10px",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontWeight: "700",
  lineHeight: "25px",
  color: "var(--clr-gray)",
  wordWrap: "break-word",
  marginBottom: "12px",
  "@media (max-width: 912px)": {
    fontSize: "22px",
  },
}));

export const LearningPointsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "2px 12px",
}));

export const IconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "4px",
  width: "18px",
  height: "18px",
}));
export const CourseInfoLearningPoints = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: "300",
  lineHeight: "24px",
  color: "var(--clr-gray)",
  wordWrap: "break-word",
  marginLeft: "16px",
  "@media (max-width: 540px)": {
    fontSize: "16px",
  },
}));

export const CourseInfoModuleContainer = styled(Box)(() => ({
  width: "100%",
  background: "var(--clr-white)",
  boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.07)",
  borderRadius: "10px",
  padding: "12px",
  marginBottom: "25px",
}));
export const CourseInfoModuleNameContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "var(--clr-white)",
  marginBottom: "5px",
  padding: "12px",
}));

export const CourseInfoModuleName = styled(Box)(() => ({
  display: "flex",
  fontSize: "22px",
  fontWeight: 700,
  fontFamily: "Poppins",
  color: "var(--clr-gray)",
}));
export const CourseModuleLecturesInfo = styled(Box)(() => ({
  display: "flex",
  fontSize: "12px",
  fontWeight: 300,
  fontFamily: "Poppins",
  lineHeight: "30px",
  color: "var(--clr-secondary)",
}));
export const StyledCourseAccordionContainer = styled(Box)(() => ({
  width: "100%",
  padding: "0px 12px",
}));
export const StyledCourseAccordion = styled(Accordion)(() => ({
  backgroundColor: "#f5f5f5",
  marginBottom: "10px",
  width: "100%",
  border: "none",
  outline: "none",
  boxShadow: "none",
}));

export const StyledCourseAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: "var(--clr-white)",
  padding: "4px 0px",
  color: "var(--clr-black)",
  fontSize: "15px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "25px",
}));

export const StyledCourseAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: "var(--clr-white)",

  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "20px",
  padding: "4px 0px",
}));
