import React, { useState } from "react";

function CourseForm() {
  const [courseData, setCourseData] = useState({
    name: "",
    homepointdescription: [],
    shortdescription: "",
    longdescription: "",
    duration: "",
    level: "",
    numberlesson: "",
    numbermodules: "",
    module: [
      {
        name: "",
        lesson: [{ name: "" }],
      },
    ],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Handle special cases like 'homepointdescription' and 'module'
    if (name === "homepointdescription") {
      const descriptions = value.split("\n");
      setCourseData({
        ...courseData,
        homepointdescription: descriptions,
      });
    } else if (name.startsWith("module")) {
      const [moduleIndex, field] = name.split("_");
      const modules = [...courseData.module];
      modules[moduleIndex][field] = value;
      setCourseData({
        ...courseData,
        module: modules,
      });
    } else {
      setCourseData({
        ...courseData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send courseData to your backend or perform any desired actions
    console.log(courseData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Course Name:
        <input
          type="text"
          name="name"
          value={courseData.name}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Home Point Descriptions (One per line):
        <textarea
          name="homepointdescription"
          value={courseData.homepointdescription.join("\n")}
          onChange={handleInputChange}
        />
      </label>
      <label>
        shortdescription
        <input
          type="text"
          name="shortdescription"
          value={courseData.shortdescription}
          onChange={handleInputChange}
        />
      </label>
      <label>
        longdescription
        <input
          type="text"
          name="longdescription"
          value={courseData.longdescription}
          onChange={handleInputChange}
        />
      </label>
      <button type="submit">Create Course</button>
    </form>
  );
}

export default CourseForm;
