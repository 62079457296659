import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true); // Component is mounted
    return () => {
      setMounted(false); // Component is unmounted
    };
  }, []);

  useEffect(() => {
    if (!token && mounted) {
      toast.error("Please login for course lecture");
      // alert("Please login for course lecture");
      navigate("/");
    }
  }, [token, navigate, mounted]);

  if (token) {
    return <Outlet />;
  } else {
    return null; // or a loading/error message
  }
};

export default ProtectedRoutes;
