import { useContactNowMutation } from "../../features/contactus/contactSlice";
import { toast } from "react-toastify";
export const useContactUs = () => {
  const [contactUsMessage] = useContactNowMutation();
  const onSubmit = async (data) => {
    try {
      const res = await contactUsMessage(data).unwrap();
      // console.log(res);
      if (res.success === true) {
        // console.log(res);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      // console.log("error", error);
      if (error.data) {
        toast.error(error?.data.message);
      }
    }
  };
  return { onSubmit };
};
