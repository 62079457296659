import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const MissionVissionMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "20px 40px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const MissionVissionTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const MissionVissionMainHeading = styled(Box)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media  (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Box)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const MissionVisionDownContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  margin: "30px 0px",
  "@media (max-width: 1250px)": {
    margin: "30px",
  },
  "@media (max-width: 912px)": {
    flexDirection: "column !important",
    margin: "30px 0px",
  },
}));

export const LeftSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "var(--clr-ash)",
  borderRadius: "28px",
  "@media (max-width: 1250px)": {
    width: "40%",
  },
  "@media (max-width: 912px)": {
    width: "100%",
  },
}));

export const LeftImage = styled("img")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "14px",
  width: "100%",
  height: "100%",
}));

export const RightSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "50%",
  justifyContent: "center",
  "@media (max-width: 912px)": {
    marginTop: "40px",
    width: "65%",
  },
  "@media (max-width: 540px)": {
    width: "100%",
  },
}));
export const RightSectionTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
export const RightMainText = styled(Box)(() => ({
  display: "flex",
  fontFamily: "Poppins",
  marginBottom: "12px",
  fontWeight: 600,
  color: "var(--clr-secondary)",
  fontSize: "20px",
  "@media (max-width: 912px)": {
    fontSize: "18px",
  },
  "@media (max-width: 540px)": {
    fontSize: "16px",
  },
}));

export const MissionVissionSubDescription = styled(Box)(() => ({
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "18px",
  lineHeight: "30px",
  color: "var(--clr-gray)",
  "@media (max-width: 912px)": {
    fontSize: "18px",
  },
  "@media (max-width: 540px)": {
    fontSize: "16px",
  },
}));
