import { Box } from "@mui/system";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export const BreadcrumbContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "2px",
  marginBottom: "10px",
}));

export const BreadcrumbLink = styled(Link)(() => ({
  color: "var(--clr-white)",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontWeight: "bold",
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
  },
}));

export const BreadcrumbSeparator = styled(ArrowForwardIosIcon)(() => ({
  width: "12px",
  height: "12px",
  color: "var(--clr-white)",
  margin: "0 4px",
}));
