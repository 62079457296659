import React, { useState } from "react";
import {
  CoursePaymentDescription,
  CoursePaymentHeading,
  CoursePaymentHeadingSection,
  CoursePaymentLeftSide,
  PaymentContainer,
  PaymentDialogDetail,
  // PaymentCourseCarousel,
  SummaryDetailContainer,
  CoursePaymentRightSide,
} from "../../../styles/ExploreCourseStyling/proceedtopay/styleCoursePayment";
import Navbar from "../../GlobalComponents/appbar/Navbar";

// import CourseSlider from "../../rightsidecarousel/CourseSlider";
import Calculation from "./totalcalculation/Calculation";
import AddingCardInfo from "./addingcardinfo/AddingCardInfo";
const CoursePayment = () => {
  const [showAddPayment, setShowAddPayment] = useState(false);
  return (
    <>
      <Navbar />
      <PaymentContainer>
        <CoursePaymentLeftSide>
          <SummaryDetailContainer>
            <CoursePaymentHeadingSection>
              <CoursePaymentHeading>Enrolling Process ...</CoursePaymentHeading>
              <CoursePaymentDescription>
                Summary of Your Course
              </CoursePaymentDescription>
            </CoursePaymentHeadingSection>
            <PaymentDialogDetail>
              <Calculation setShowAddPayment={setShowAddPayment} />
            </PaymentDialogDetail>
          </SummaryDetailContainer>
        </CoursePaymentLeftSide>
        <CoursePaymentRightSide>
          {showAddPayment ? (
            <AddingCardInfo />
          ) : (
            ""
            // <SummaryDetailContainer>
            //   <CoursePaymentHeadingSection>
            //     <CoursePaymentHeading>Related Courses </CoursePaymentHeading>
            //     <CoursePaymentDescription>
            //       Suggested Courses
            //     </CoursePaymentDescription>
            //   </CoursePaymentHeadingSection>
            //   <PaymentCourseCarousel>
            //     <CourseSlider />
            //   </PaymentCourseCarousel>
            // </SummaryDetailContainer>
          )}
        </CoursePaymentRightSide>
      </PaymentContainer>
    </>
  );
};

export default CoursePayment;
