import React from "react";
import Navbar from "../../GlobalComponents/appbar/Navbar";
import {
  BlogContentContainer,
  SingleBlogButton,
  SingleBlogButtonContainer,
  SingleBlogDateInfo,
  SingleBlogImageContainer,
  SingleBlogMainContainer,
  SingleBlogSubTitle,
  SingleBlogTitle,
  SingleBlogTitleContainer,
  SubTitleDateContainer,
  TitleButtonContainer,
} from "../../../styles/BlogsStyling/singleblog/styleSingleBlog";
import BlogImg from "../../../assets/images/blogimg.jpg";
import Footer from "../../GlobalComponents/footer/Footer";
import { useNavigate } from "react-router-dom";
const SingleBlog = () => {
  const navigate = useNavigate();
  const handleBlockBack = () => {
    navigate("/blogs");
  };
  return (
    <>
      <Navbar />
      <SingleBlogMainContainer>
        <TitleButtonContainer>
          <SingleBlogTitleContainer>
            <SingleBlogTitle>STEAM Minds</SingleBlogTitle>
          </SingleBlogTitleContainer>
          <SingleBlogButtonContainer>
            <SingleBlogButton
              style={{
                border: "1px solid var(--clr-secondary)",
              }}
              onClick={handleBlockBack}
            >
              Back to Blogs
            </SingleBlogButton>
          </SingleBlogButtonContainer>
        </TitleButtonContainer>
        <SingleBlogImageContainer>
          <img
            src={BlogImg}
            alt="Blog"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </SingleBlogImageContainer>
        <SubTitleDateContainer>
          <SingleBlogSubTitle>STEAM Education</SingleBlogSubTitle>
          <SingleBlogDateInfo>28, july 2023</SingleBlogDateInfo>
        </SubTitleDateContainer>
        <hr />
        <BlogContentContainer>
          <h3>What is STEM?</h3> <br />
          <p>
            STEM or broadly known as STEAM is multi disciplinary approach. This
            approach involves the basic core subjects of science, Technology,
            engineering and Maths. The more broader approach is an integration
            with arts and known as STEAM. The ability to teach subjects in
            coherence with real world approaches and creating a diverse ability
            to cater thoughts and creativity, rather than individual approach is
            the crux of STEAM education.
          </p>
          <br />
          <h3>Why do we need STEAM ?</h3> <br />
          <p>
            STEAM education and the use of technology enable all students to
            express their creativity and strengthen their critical thinking
            abilities. STEAM education has several advantages for learners of
            all ages and origins.
            <br /> We need STEAM because:
          </p>
          <ul>
            <li>
              Students who are involved in practical approach of learning shows
              far better results than those who merely memorize facts.
            </li>
            <li>
              The current paradigm of Science and technology needs dynamic
              approaches to practice the facts in real time scenarios.
            </li>
            <li>
              The world needs problem solvers. Those who can inspect, analyse,
              model, design and implement the practices as mitigation
              techniques.
            </li>
            <li>
              Target audience are the kids of Elementary,Middle and high
              schools.
            </li>
          </ul>
          <br />
          <h3>Advantages of STEAM?</h3> <br />
          <ul>
            <li>It improves problem solving skills of a child.</li>
            <li>
              It helps children to analyse and learn through project based
              learning.
            </li>
            <li>
              With coherence children understand the concepts fully as well as
              the ability to implement it in the real life.
            </li>
            <li>
              It helps the children with special needs to evolve and equally
              participate in the learning process.
            </li>
            <li>
              The twice exceptional children have shown better results with this
              practical and creative approach.
            </li>
          </ul>
        </BlogContentContainer>
      </SingleBlogMainContainer>
      {/* Single Blog with ID #: {id} */}
      <Footer />
    </>
  );
};

export default SingleBlog;
