import React from "react";
// import // HeroContainer,
// // HeadingTextContainer,
// // HeadingText,
// // BodyText,
// // HeroButton,
// // ImageContainer,
// // ProgressBannerContainer,
// // NameContainer,
// // Number,
// // Name,
// // RightBorder,
// "../../../styles/herosection/heroSectionStyle";
// import HomeHeroContainer from "../../../styles/HomeStyling/herosection/heroSectionStyle";
import HeroRightImage from "../../../assets/images/student.png";
import {
  HeadingTextContainer,
  HomeHeroContainer,
  HeadingText,
  BodyText,
  HeroButton,
  ProgressBannerContainer,
  NameContainer,
  Number,
  Name,
  RightBorder,
  ImageContainer,
} from "../../../styles/HomeStyling/herosection/heroSectionStyle";
import { useNavigate } from "react-router-dom";
const HeroSection = () => {
  const navigate = useNavigate();
  const handleLearnMore = () => {
    navigate("/explore-allcourses");
  };
  return (
    <>
      <HomeHeroContainer>
        <HeadingTextContainer>
          <HeadingText>
            Empowering Minds with{" "}
            <span style={{ color: "#504298" }}>STEAM</span> Minds
          </HeadingText>
          <BodyText>
            We make learning an immersive adventure with STEAM Minds, bringing
            together the power of STEAM education to inspire, engage, and
            empower learners of all ages.
          </BodyText>
          <HeroButton onClick={handleLearnMore}>Learn with Us</HeroButton>
          <ProgressBannerContainer>
            <NameContainer>
              <Number>40 +</Number>
              <Name>Courses</Name>
            </NameContainer>
            <RightBorder></RightBorder>
            <NameContainer>
              <Number>20 +</Number>
              <Name>Expert Mentors</Name>
            </NameContainer>
            <RightBorder></RightBorder>
            <NameContainer>
              <Number>1k +</Number>
              <Name>Students Globally</Name>
            </NameContainer>
          </ProgressBannerContainer>
        </HeadingTextContainer>

        <ImageContainer>
          <img src={HeroRightImage} alt="student" style={{ width: "100%" }} />
        </ImageContainer>
      </HomeHeroContainer>
    </>
  );
};

export default HeroSection;
