import { styled } from "@mui/system";
import { Button, Card, Typography } from "@mui/material";

export const MobileCourseCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  border: "0.5px solid #5B57E4",
  boxShadow: "0px 4px 8px 1px rgba(69, 64, 225, 0.25)",
  borderRadius: "14px",
  padding: "14px",
  width: "100%",

  marginTop: "40px",
}));

export const MobileCourseImage = styled("img")(() => ({
  width: "120px",
  height: "120px",
}));

export const MobileHeadingCourseSubText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "20px",
  marginTop: "12px",
  // textAlign: "justify",
  color: "var(--clr-black)",
}));

export const MobileCourseName = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "30px",
  lineHeight: "49px",
  leadingTrim: "both",
}));

export const MobileExploreButton = styled(Button)(() => ({
  borderRadius: "15px",
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  padding: "8px",
  border: "1px solid #5B57E4",
}));
