import { Card } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const AllBlogsMainContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  flexDirection: "column",
  margin: "30px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const BlogsCardContainer = styled(Box)(() => ({
  display: "grid",
  marginTop: "35px",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "20px",
  "@media (max-width: 912px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 480px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const BlogsCustomCard = styled(Card)(() => ({
  width: "100%",
  // height: "480px",
  cursor: "pointer",
  borderRadius: "10px",
  position: "relative",
}));

export const BlogsCardImage = styled(Box)(() => ({
  width: "100%",
  height: "340px",
  position: "relative",
  "@media (max-width: 540px)": {
    height: "300px",
  },
}));
export const BlogUpperDivison = styled(Box)(() => ({
  position: "absolute",
  top: "70%", // Adjust this value to your preference
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  zIndex: 5,
  padding: "5px",
  margin: "5px",
}));
export const BlogTitle = styled(Box)(() => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "25px",
  color: "var(--clr-white)",
  lineHeight: "38px",
  "@media (max-width: 820px)": {
    fontSize: "20px",
  },
  "@media (max-width: 540px)": {
    fontSize: "18px",
  },
}));

export const BlogDate = styled(Box)(() => ({
  display: "flex",
  alignItems: "end",
  justifyContent: "flex-end",
  marginRight: "10px",
  marginTop: "5px",
  paddingTop: "10px",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "14px",
  color: "var(--clr-white)",
  lineHeight: "28px",
}));

// export const CustomBlogDivider = styled(Divider)(() => ({
//   color: "var(--clr-white)",
//   width: "80%",
// }));
// export const CustomBlogDivider = styled(Divider)(({ theme }) => ({
//   backgroundColor: theme.palette.common.white,
//   width: "80%",
// }));
export const ReadMoreButtonContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "12px",
  borderTop: `1px solid white`,
  paddingTop: "10px",
  width: "97%",
}));

export const ReadMoreButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  color: "var(--clr-white)",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "20px",
  letterSpacing: "1px",
}));

export const ReadMoreArrow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  color: "var(--clr-white)",
  fontSize: "14px",
}));
