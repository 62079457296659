import React from "react";
import {
  VideoContainer,
  VideoLeftSection,
  // VideoButton,
  VideoMainContainer,
  VideoRightSection,
  RightTopTextContainer,
  RightMainTextContainer,
  RightMainText,
  CustomCircleIcon,
  VideoTextContainer,
  VideoIconTextContainer,
  VideoSmallText,
} from "../../../styles/HomeStyling/powerofsteammind/styleVideoSection";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetBanner } from "../../../customhooks/homeCustomHooks/useBanner";

const VideoSection = () => {
  const { Announcement, isLoading, isSuccess } = useGetBanner();

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <VideoMainContainer>
          {Announcement.map((announcement) => (
            <React.Fragment key={announcement?.announcementId}>
              <VideoLeftSection>
                <VideoContainer>
                  {/* <CustomVideo controls>
              {" "}
              <source src={SteamMinds} type="video/webm" />
            </CustomVideo> */}

                  <img
                    src={announcement?.announcementimage}
                    alt="title"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10%",
                    }}
                  />

                  {/* <VideoButton>Watch Now</VideoButton> */}
                </VideoContainer>
              </VideoLeftSection>
              <VideoRightSection>
                <RightTopTextContainer>
                  <RightMainTextContainer>
                    <RightMainText>
                      {announcement?.announcementTitle}
                    </RightMainText>
                    {announcement?.announcementDescription.map(
                      (description, index) => (
                        <VideoIconTextContainer key={index}>
                          {/* <VideoIconContainer> */}
                          {/* <CheckCircleIcon
                            style={{
                              color: "var(--clr-white)",
                              width: "45px",
                              height: "45px",
                            }}
                          /> */}
                          <CustomCircleIcon />
                          {/* </VideoIconContainer> */}
                          <VideoTextContainer>
                            <VideoSmallText>{description}</VideoSmallText>
                          </VideoTextContainer>
                        </VideoIconTextContainer>
                      )
                    )}
                  </RightMainTextContainer>
                </RightTopTextContainer>
              </VideoRightSection>
            </React.Fragment>
          ))}
        </VideoMainContainer>
      </>
    );
  }
};

export default VideoSection;
