import { useMemo } from "react";
import { useGetAmbassadorQuery } from "../../features/ambassadors/topbardata/ambassadorSlice";
import { useGetAmbassadorInfoQuery } from "../../features/ambassadors/ambassadorinfo/ambassadorInfoSlice";

export const useGetAmbassador = () => {
  const { data, isSuccess, isLoading } = useGetAmbassadorQuery();
  const AmbassadorInfo = useMemo(() => {
    const getAmbassadorTopBanner = data || [];

    return getAmbassadorTopBanner?.map((topData) => ({
      TopBannerId: topData?._id,
      //   TopBannerTitle: topData?.title,
      TopBannerDescription: topData?.description,
      TopBannerImage: topData?.image,
    }));
  }, [data]);
  return {
    TopBannerAmbassadorData: AmbassadorInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetAmbassadorInfo = () => {
  const { data, isSuccess, isLoading } = useGetAmbassadorInfoQuery();
  const AmbassadorInfo = useMemo(() => {
    const getAmbassadorInformation = data || [];

    return getAmbassadorInformation?.map((ambassador) => ({
      ambassadorId: ambassador?._id,
      ambassadorName: ambassador?.member_name,
      ambassadordesignation: ambassador?.designation,
      ambassadorDescription: ambassador?.description,
      ambassadorImage: ambassador?.image,
    }));
  }, [data]);
  return {
    AmbassadorInfo: AmbassadorInfo,
    isLoading,
    isSuccess,
  };
};
