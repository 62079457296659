import React from "react";
import { Grid } from "@mui/material";
import {
  MobileCourseCard,
  MobileCourseImage,
  MobileCourseName,
  MobileHeadingCourseSubText,
} from "../../../../styles/HomeStyling/coursesection/mobilecoursecategory/stylemobilecategory";
import { useGetCategory } from "../../../../customhooks/homeCustomHooks/category/useCategory";
import CircularProgress from "@mui/material/CircularProgress";
const MobileCourseCategory = () => {
  const { Category, isLoading, isSuccess } = useGetCategory();
  const borderColors = ["#EA614E", "#08C5D1", "#FC9A07", "#F4CF0C", "#504298"];
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <Grid container spacing={2}>
        {Category.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item key={item.id} xs={12}>
              <MobileCourseCard
                style={{ border: `0.5px solid ${borderColors[index]}` }}
              >
                <MobileCourseImage src={item?.categoryImage} />
                <MobileCourseName>{item?.categoryName}</MobileCourseName>

                <MobileHeadingCourseSubText>
                  {item?.categoryDescription}
                </MobileHeadingCourseSubText>
              </MobileCourseCard>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }
};

export default MobileCourseCategory;
