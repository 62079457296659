import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";
import formRightImage from "../../../assets/images/formrightimg.png";
export const ContactFormSection = styled(Box)(() => ({
  position: "absolute",
  top: -200,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  zIndex: 1,
  height: "550px",
  background: "var(--clr-white)",
  padding: "12px",
  margin: "0px 120px",
  boxShadow: "0px 20px 30px 0px rgba(102, 106, 245, 0.13)",
  borderRadius: "14px",
  "@media (max-width: 912px)": {
    margin: "0px",
  },
}));

export const ContactMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "8px 5px",
  width: "100%",
}));
export const ContactFormHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));
export const ContactMainHeading = styled(Typography)(() => ({
  display: "flex",
  fontFamily: "Poppins",
  fontSize: "48px",
  fontWeight: 700,
  color: "var(--clr-secondary)",
  "@media  (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "18px",
  },
}));

export const ContactSubText = styled(Typography)(() => ({
  display: "flex",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  color: "var(--clr-black)",
}));

export const FormImageContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "space-between",
  // width: "100%",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
}));

export const FormLeftContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "50%",
  margin: "30px 15px 15px 0px",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const CustomFormInput = styled("input")(() => ({
  display: "flex",

  width: "100%",
  padding: "14px 14px",
  border: "none",
  outline: "none",
  borderRadius: "7px",
  fontSize: "12px",
  fontFamily: "Poppins",
  marginBottom: "5px",
  marginTop: "12px",
  background: "var(--clr-input-bg)",
  color: "var(--clr-input-text)",
}));

export const CustomFormTextArea = styled("textarea")(() => ({
  display: "flex",
  resize: "none",
  width: "100%",
  padding: "8px 14px",
  border: "none",
  outline: "none",
  borderRadius: "7px",
  fontSize: "12px",
  fontFamily: "Poppins",
  marginBottom: "14px",
  marginTop: "12px",
  background: "var(--clr-input-bg)",
  color: "var(--clr-input-text)",
}));

export const CustomFormButton = styled(Button)(() => ({
  display: "flex",
  padding: "9px",
  background: "var(--clr-secondary)",
  width: "100%",
  borderRadius: "30px",
  color: "var(--clr-white)",
  fontSize: "15px",
  fontFamily: "Poppins",
  fontWeight: 700,
  marginBottom: "20px",
  textTransform: "capitalize",
  letterSpacing: "1.6px",
  margin: "8px 0px",
  "&:hover": {
    backgroundColor: "var(--clr-white)",
    border: `0.5px solid var(--clr-secondary)`,
    color: "var(--clr-secondary)",
  },
}));

export const FormRightContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "50%",
  // background: "var(--clr-input-bg)",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    width: "50%",
  },
}));

export const FormRightImageContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "80%",
  height: "240px",
  backgroundImage: `url(${formRightImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const ContactInfoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "@media (max-width: 768px)": {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",
    width: "50%",
  },
}));

export const ContactIconTextContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  width: "100%",
  margin: "4px 8px",
  "@media (max-width: 768px)": {
    margin: "0px",
    padding: "0px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-start",
    width: "50%",
  },
}));

export const FormIconImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  border: `0.5px solid var(--clr-secondary)`,
  backgroundColor: "var(--clr-white)",
  borderRadius: "50%",
  margin: "0px 8px",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const FormIconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "var(--clr-white)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const FormSocialMediaContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "end",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "12px",
  marginLeft: "20px",
  marginRight: "-54px",
  marginBottom: "0px",
  padding: "5px 20px",
  "@media (max-width: 768px)": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    // background: "green",
    marginRight: "0px",
    marginTop: "27%",
    marginLeft: "90%",
  },
  "@media (max-width: 540px)": {
    width: "200px",
    marginTop: "40%",
  },
  "@media (max-width: 500px)": {
    marginTop: "60%",
  },
  "@media (max-width: 380px)": {
    marginTop: "50%",
  },
}));

export const FormSocialIconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "var(--clr-secondary)",
  borderRadius: "50px 10px 0px 0px",
  width: "50%",
  height: "35px",
  padding: "5px 20px",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));
