import { Select, MenuItem, Card, FormControl } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const AllCourseMainContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  flexDirection: "column",
  margin: "30px",
  "@media (max-width: 912px)": {
    margin: "30px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const MainDropDown = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center",
}));
export const DropDownContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "70%",
  margin: "10px 50px",
  borderRadius: "37px",
  padding: "7px 10px",
  "@media (max-width: 540px)": {
    flexDirection: "column",
    width: "100%",
    margin: "5px 10px",
  },
}));
export const CustomDropDownFormControl = styled(FormControl)(() => ({
  width: "300px",
  margin: "5px 15px",
  "@media (max-width: 540px)": {
    width: "100%",
  },
}));

export const CustomSelect = styled(Select)(() => ({
  borderRadius: "45px",
  width: "100%",
  height: "45px",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "var--clr-dropdown-head)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--clr-dropdown-head)",
    border: `0.7px solid `,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--clr-dropdown-head)",
  },
  "@media (max-width: 540px)": {
    marginBottom: "10px",
  },
}));

export const CustomMenuItem = styled(MenuItem)(() => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
}));

export const CardContainer = styled(Box)(() => ({
  display: "grid",
  marginTop: "35px",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "15px",
  "@media (max-width: 912px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 540px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  "@media (max-width: 480px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const CustomCard = styled(Card)(() => ({
  width: "100%",
  height: "525px",
  cursor: "pointer",
  borderRadius: "20px",
  "@media (max-width: 912px)": {
    width: "100%",
    // height: "auto",
  },
}));

export const CardImage = styled(Box)(() => ({
  width: "100%",
  height: "250px",
  "@media (max-width: 540px)": {
    height: "250px",
  },
}));
export const DownSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "10px",
  height: "270px",
  marginTop: "10px",
  "@media (max-width: 500px)": {
    height: "auto",
  },
}));
export const CardCourseHeading = styled(Box)(() => ({
  color: "black",
  fontSize: "22px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0px",
  "@media (max-width: 500px)": {
    fontSize: "17px",
  },
}));
export const IconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "7px",
}));
export const IconContainer = styled(Box)(() => ({
  display: "flex",
  width: "15px",
  height: "15px",
  alignItems: "center",
  marginTop: "7px",
}));

export const TextContainer = styled(Box)(() => ({
  marginTop: "8px",
}));
export const CardCourseDescription = styled(Box)(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  width: "100%",
  fontSize: "14px",
  marginLeft: "14px",

  color: "var(--clr-gray)",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const CourseCardLearnMoreButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "17px",
  background: "var(--clr-secondary)",
  padding: "5px",
  borderRadius: "30px",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  color: "var(--clr-white)",
  cursor: "pointer",
}));
