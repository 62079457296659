import { Checkbox, Modal, Paper, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import LogoImage from "../../../assets/images/logo.png";
import PhoneInput from "react-phone-input-2";

export const CustomModal = styled(Modal)(() => ({
  margin: "40px 80px",
 
  "@media (max-width: 912px)": {
    margin: "70px 80px",
  },
  "@media (max-width: 770px)": {
    margin: "40px 50px",
  },
  "@media (max-width: 540px)": {
    margin: "20px ",
    height: "90%",
  },
}));

export const CustomPaper = styled(Paper)(() => ({
  background: "#ffffff",
  "@media (max-width: 540px)": {
    height: "80%",
  },
}));

export const MainContainer = styled(Box)(() => ({
  background: "#ffffff",
  display: "flex",
  "@media (max-width: 912px)": {
    flexDirection: "column",
  },
  // "@media (max-width: 540px)": {
  //   height: "100%",
  // },
}));

export const LeftSection = styled(Box)(() => ({
  background: "linear-gradient(324.93deg, #504298 0.74%, #705DD1 100%)",
  padding: "12px",
  width: "580px",
  "@media (max-width: 912px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "@media (max-width: 820px)": {
    display: "none",
  },
}));

export const LogoContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "12px",
  width: "80px",
  height: "95px",
  background: "#FFFFFF",
  boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.25)",
  borderRadius: "7px",
  backgroundImage: `url(${LogoImage})`,
  backgroundSize: "fill",
  backgroundPosition: "center",
  objectFit: "cover",
  backgroundRepeat: "no-repeat",
  "@media (max-width: 912px)": {
    width: "75px",
    height: "90px",
    margin: "0px",
  },
  "@media (max-width: 768px)": {
    marginTop: "4px",
  },
}));
export const MainCarouselContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px",
  width: "370px",
  height: "380px",
  "@media (max-width: 912px)": {
    margin: "0px",
    padding: "0px",
    width: "100%",
    height: "270px",
    marginTop: "-20px",
  },
}));
export const SignUpLeftMainText = styled(Typography)(() => ({
  display: "flex",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "15px",
  lineHeight: "22px",
  letterSpacing: "0px",
  color: "#FFFFFF",
  textAlign: "center",
  marginTop: "270px",
  "@media (max-width: 540px)": {
    fontSize: "12px",
  },
}));

export const RightSection = styled(Box)(() => ({
  background: "#FFFFFF",
  margin: "8px",
  padding: "8px",
  width: "100%",
  "@media (max-width: 912px)": {
    width: "90%",
  },
  "@media (max-width: 540px)": {
    width: "95%",
  },
}));

export const InfoSection = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "-10px",
}));
export const RightTopSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
export const SupportTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginRight: "20px",
}));

export const SupportText = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "18px",
  fontStyle: "normal",
  color: "#504298",
  "@media (max-width: 540px)": {
    fontSize: "10px",
    lineHeight: "12px",
  },
}));

export const LanguageDropDownContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px 22px",
}));

export const StyledSelect = styled(Select)(() => ({
  backgroundColor: "#ffffff",
  ".& focus": {
    backgroundColor: "#FFFFFF",
  },
}));

export const RightMainHeadingContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  margin: "10px 40px",
  padding: "8px",
  "@media (max-width: 540px)": {
    margin: "10px",
    padding: "5px",
  },
  "@media (max-width: 390px)": {
    margin: "5px",
    padding: "2px",
  },
}));

export const MainHeadingText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "31px",
  fontWeight: 600,
  lineHeight: "35px",
  fontStyle: "normal",
  color: "#1D1D1D",
  "@media (max-width: 540px)": {
    fontSize: "25px",
    lineHeight: "28px",
  },
  "@media (max-width: 390px)": {
    fontSize: "22px",
    lineHeight: "24px",
  },
}));

export const RightSubText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "22px",
  fontStyle: "normal",
  marginTop: "8px",
  color: "#2D3748",
  "@media (max-width: 390px)": {
    fontSize: "12px",
    lineHeight: "12px",
    marginTop: "5px",
  },
}));

export const FormContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex",
  margin: "0px 40px",
  padding: "0px 15px",
  "@media (max-width: 540px)": {
    margin: "10px",
    padding: "5px",
  },
  "@media (max-width: 390px)": {
    margin: "4px",
  },
}));
export const FormLabel = styled("label")(() => ({
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "13px",
  lineHeight: "26px",
  marginBottom: "5px",
}));

export const CustomPhoneInput = styled(PhoneInput)(() => ({
  ".form-control": {
    width: "100% ",
    height: "31px",
    border: "0.705598px solid #D1D1D1",
    paddingLeft: "45px",
    borderRadius: "3.52799px",
    backgroundColor: "#FFFFFF",
  },
}));
export const CustomTextField = styled("input")(() => ({
  width: "100%",
  border: "0.705598px solid #D1D1D1",
  padding: "7px",
  borderRadius: "3.52799px",
  backgroundColor: "#FFFFFF",
  "@media (max-width: 390px)": {
    padding: "5px",
  },
}));

export const FormBottomSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "4px 40px",
  padding: "8px",
  "@media (max-width: 540px)": {
    margin: "4px 10px",
    Padding: "4px",
  },
}));

export const RememberMeContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  // marginTop: "-10px",
}));
export const CustomCheckBox = styled(Checkbox)(() => ({
  marginLeft: "-5px",
  marginTop: "-12px",
  borderColor: "#D1D1D1",
}));
export const RememberMeText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 300,
  lineHeight: "7px",
  fontStyle: "normal",
  marginTop: "5px",
  marginLeft: "-3px",
  color: "#2D3748",
  "@media (max-width: 390px)": {
    lineHeight: "14px",
  },
}));

export const ForgotPassword = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "11px",
  fontWeight: 300,
  lineHeight: "7px",
  fontStyle: "normal",
  marginTop: "5px",
  marginLeft: "-3px",
  color: "#7054FF",
  "@media (max-width: 390px)": {
    lineHeight: "14px",
  },
}));

export const PrivacyContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  marginTop: "-30px",
  "@media (max-width: 540px)": {
    marginTop: "-20px",
  },
}));

export const CreateAccountButton = styled("button")(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "7px",
  fontStyle: "normal",
  width: "99%",
  height: "40px",
  Padding: "18px",
  background: "#5748A4",
  borderRadius: "18.9557px",
  border: "none",
  color: "#FFFFFF",
  "@media (max-width: 912px)": {
    marginBottom: "20px",
  },
}));

export const SignInGoogleButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "97%",
  height: "40px",
  Padding: "18px",
  background: "#2D3748",
  borderRadius: "18.9557px",
  border: "none",
  color: "#FFFFFF",
  cursor: "pointer",
}));

export const GoogleSmallImage = styled("img")(() => ({
  width: "20px",
  height: "20px",
  marginRight: "14px",
}));

export const SignINWithGoogle = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "7px",
  fontStyle: "normal",
  color: "#FFFFFF",
}));

export const AlreadyAccountContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
}));

export const AlreadyAccountText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "7px",
  fontStyle: "normal",
  color: "#2D3748s",
  "@media (max-width: 540px)": {
    marginTop: "-40px",
  },
}));

export const SmallTopBar = styled(Box)(() => ({
  alignItems: "flex-start",
  width: "100%",
  height: "120px",
  padding: "12px",
  background: "linear-gradient(324.93deg, #504298 0.74%, #705DD1 100%)",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  display: "none",

  "@media (max-width: 820px)": {
    display: "block",
  },
  "@media (max-width: 770px)": {
    height: "120px",
  },
}));

export const SignInBottomSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "8px 40px",
  padding: "10px",
  "@media (max-width: 540px)": {
    margin: "5px 20px",
  },
}));

export const RegisterGoogleButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "40px",
  Padding: "18px",
  background: "#2D3748",
  borderRadius: "18.9557px",
  border: "none",
  color: "#FFFFFF",
  cursor: "pointer",
  marginTop: "15px",
}));
