import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import HeroBackground from "../../../assets/images/productbannerbg.jpg";
export const ProductHeroMainContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 60px",
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${HeroBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  "@media (max-width: 912px)": {
    height: "55vh",
  },
  "@media (max-width: 820px)": {
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    justifyContent: "space-evenly",
    padding: "20px 40px",
    // height: "100vh",
  },
  "@media (max-width: 540px)": {
    height: "100%",
  },
}));

export const ProductLeftSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "@media (max-width: 912px)": {
    marginBottom: "50px",
  },
}));

export const ProductLeftSideContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const ProductLeftSideMainHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "50px",
  fontWeight: 700,
  lineHeight: "66px",
  padding: "35px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    fontSize: "45px",
    lineHeight: "55px",
  },
  "@media (max-width: 820px)": {
    fontSize: "40px",
    lineHeight: "50px",
  },
  "@media (max-width: 540px)": {
    fontSize: "35px",
    lineHeight: "28px",
  },
}));

export const ProductLeftSideSubHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "17px",
  fontWeight: 300,
  // textAlign: "justify",
  lineHeight: "30px",
  letterSpacing: "0.4px",
  padding: "15px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    lineHeight: "28px",
    letterSpacing: "0.2px",
  },
}));

export const ProductLeftButtonContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  marginTop: "20px",
  marginBottom: "20px",
  width: "70%",
  "@media (max-width: 912px)": {
    width: "100%",
  },
  "@media (max-width: 820px)": {
    width: "50%",
  },
  "@media (max-width: 540px)": {
    width: "100%",
  },
  "@media (max-width: 400px)": {
    flexDirection: "column",
  },
}));

export const ProductPlayStoreButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  width: "50%",
  height: "50px",
  "@media (max-width: 400px)": {
    width: "100%",
    marginTop: "20px",
  },
}));

export const ProductImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "350px",
  marginLeft: "2%",
  // marginTop: "10%",
  objectFit: "cover",
  "@media (max-width: 912px)": {
    width: "100%",
    margin: "20px",
  },
  "@media (max-width: 820px)": {
    marginBottom: "30%",
    height: "300px",
    marginLeft: "0px",
  },
}));
