import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";

export const ProductsMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "20px 40px",
  },
  "@media (max-width: 540px)": {
    margin: "20px",
    width: "90%",
  },
}));

export const ProductsTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const ProductsMainHeading = styled(Box)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media  (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
}));

export const ProductSubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Box)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const ProductCardMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
    padding: "0px",
  },
}));

export const ProductCardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const ProductCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  background: "#FFFFFF",
  boxShadow: "0px 4px 8px 1px rgba(69, 64, 225, 0.25)",
  borderRadius: "14px",
  padding: "14px",
  width: "100%",
  height: "auto",
  // textAlign: "justify",
  marginBottom: "40px",
  border: `0.5px solid var(--clr-secondary)`,
  "@media (max-width: 912px)": {
    flexDirection: "column !important",
  },
  "@media (max-width: 540px)": {
    padding: "0px",
    width: "100%",
  },
}));

export const ProductImageContainer = styled(Box)(() => ({
  display: "flex",
  //   border: "1px solid var(--clr-secondary)",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
  //   height: "220px",

  overflow: "hidden",
  "@media (max-width: 540px)": {
    marginLeft: "0px",
    paddingTop: "20px",
  },
}));

export const ProductRightSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "50px 70px",
  width: "100%",
  "@media (max-width: 540px)": {
    padding: "12px",
  },
}));

export const ProductName = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "45px",
  lineHeight: "28px",
  marginBottom: "20px",
  "@media (max-width: 540px)": {
    fontSize: "25px",
    lineHeight: "18px",
    marginTop: "20px",
  },
}));

export const ProductIconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "7px",
  width: "100%",
}));
export const ProductIconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "7px",
}));

export const ProductTextContainer = styled(Box)(() => ({
  marginTop: "8px",
  width: "100%",
}));

export const ProductSubText = styled(Typography)(({ expanded }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  width: "100%",
  fontSize: "17px",
  marginLeft: "12px",
  color: "var(--clr-gray)",

  "@media (max-width: 768px)": {
    width: "100%",
    marginLeft: "0px",
  },
}));

export const ProductButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
}));

export const ProductButton = styled(NavLink)(() => ({
  display: "flex",
  textDecoration: "none",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-secondary)",
  color: "var(--clr-white)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 300,
  lineHeight: "22px",
  textTransform: "capitalize",
  borderRadius: "37px",
  padding: "8px 16px",
  width: "222px",
  "&:hover": {
    backgroundColor: "var(--clr-white)",
    border: `0.5px solid var(--clr-secondary)`,
    color: "var(--clr-secondary)",
  },
}));
