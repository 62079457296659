import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const QuestionsMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const QuestionsTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const QuestionMainHeading = styled(Typography)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
    lineHeight: "28px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const QuestionsAccordianContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "20px",
  width: "80%",
}));

export const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: "#f5f5f5",

  marginBottom: "10px",
  width: "100%",
  borderLeft: "3px solid #504298",
  borderRadius: "5px",
  boxShadow: "0px 0px 10px 0px rgba(80, 66, 152, 0.19)",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "25px",
  "@media (max-width: 540px)": {
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: "var(--clr-white)",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "20px",
}));
