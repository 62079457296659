import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Pagination } from "swiper";
import FirstSlideImage from "../../../src/assets/images/sliderfirstimage.png";
import SecondSlideImage from "../../../src/assets/images/slidersecondimage.png";
import ThirdSlideImage from "../../../src/assets/images/sliderthirdimage.png";
import { SliderMainImage } from "../../styles/slider/sliderStyle";
import "swiper/css";
import "swiper/css/pagination";
const AuthenticationSlider = () => {
  const slides = [
    { id: 1, image: FirstSlideImage, alt: "First Student" },
    { id: 2, image: SecondSlideImage, alt: "Second Student" },
    { id: 3, image: ThirdSlideImage, alt: "Third Student" },
  ];
  return (
    <>
      <Swiper
        spaceBetween={30}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        style={{
          width: "100%", // Set width to 100%
          height: "100%",
          objectFit: "cover",
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide
            key={slide.id}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SliderMainImage src={slide.image} alt={slide.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default AuthenticationSlider;
