import React, { useState } from "react";
import Navbar from "../../../../GlobalComponents/appbar/Navbar";
import {
  Box,
  // Card,
  // CardContent,
  // CardMedia,
  // Grid,
  Modal,
  // Stack,
  // Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { useGetLessonVideoByLessonIdQuery } from "../../../../../features/coursesLessons/courselessonvideos/courseLessonVideoSlice";
import {
  CourseVideoCardHeading,
  CustomVideoCard,
  CustomVideoCardImage,
  DescriptionContainer,
  VideoCourseCardDescription,
  VideoDescriptionContainer,
  VideoLectureContainer,
  VideoLectureMainContainer,
} from "../../../../../styles/ExploreCourseStyling/singleCourse/singlecoursedetailinfo/coursevideo/styleCourseVideo";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../../../GlobalComponents/footer/Footer";
const CourseVideo = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = useParams();
  const { data: CourseVideoDetail, isLoading } =
    useGetLessonVideoByLessonIdQuery(id);

  const handleCloseModal = () => {
    setSelectedVideo(null);
    setModalOpen(false);
  };
  const handleCardClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setModalOpen(true);
  };
  const lessonVideos = CourseVideoDetail?.lesson.lessonId?.lecturevideos;

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            // height: "0vh", // Set height to the full viewport height
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <VideoLectureMainContainer>
          <VideoLectureContainer>
            {lessonVideos.map((videoData) => (
              <CustomVideoCard
                key={videoData?._id}
                onClick={() => handleCardClick(videoData?.video?.videourl)}
              >
                <CustomVideoCardImage>
                  <img
                    src={videoData?.video?.image}
                    alt="steam video thumbnail"
                    style={{ width: "100%", height: "100%" }}
                  />
                </CustomVideoCardImage>
                <DescriptionContainer>
                  <CourseVideoCardHeading>
                    {videoData?.video?.name}
                  </CourseVideoCardHeading>
                  <VideoDescriptionContainer>
                    <VideoCourseCardDescription>
                      {videoData?.video?.description}
                    </VideoCourseCardDescription>
                  </VideoDescriptionContainer>
                </DescriptionContainer>
              </CustomVideoCard>
            ))}
          </VideoLectureContainer>
        </VideoLectureMainContainer>
      )}
      <Footer />
      {selectedVideo && (
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            position: "absolute",
            top: "20%",
            left: "20%",
            right: "20%",
            bottom: "20%",
            justifyContent: "center",
            alignItems: "center",
            height: "64%",
            width: "60%",
          }}
        >
          <Box
            sx={{
              outline: "none",
              width: "100%", // adjust the width as needed
              height: "100%", // adjust the height as needed
              background: "white",
              borderRadius: 2,
              backgroundColor: "black",
              overflow: "hidden",
            }}
          >
            <video controls width="100%" height="100%">
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CourseVideo;

// <Box
//   sx={{
//     display: "flex",
//     width: "100%",
//     height: "100%",
//     justifyContent: "center",
//     alignItems: "center",
//   }}
// >
//   <Modal
//     open={modalOpen}
//     onClose={handleCloseModal}
//     sx={{
//       position: "absolute",
//       top: "20%",
//       left: "20%",
//       right: "20%",
//       bottom: "20%",
//       // transform: "translate(-50%, -50%)",
//       width: "60%",
//       height: "50%",
//     }}
//   >
//     <Box>
//       <video controls width="100%" height="100%">
//         <source src={selectedVideo} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//     </Box>
//   </Modal>
// </Box>
