import React from "react";
import {
  CategoryContainer,
  CategoryMainHeading,
  CategoryTextContainer,
  CustomCurve,
  CustomSmallSubText,
  CustomSubText,
  // MobileTopButton,
  // MobileTopButtonContainer,
  SubSmallTextContainer,
  SubTextContainer,
  // TopButton,
  // TopButtonContainer,
} from "../../../styles/HomeStyling/coursecategory/courseCategoryStyle";

const ExploreCategory = () => {
  return (
    <>
      <CategoryContainer>
        <CategoryTextContainer>
          <CategoryMainHeading>
            Explore our <span style={{ color: "#504298" }}>Courses</span>
          </CategoryMainHeading>
          <CustomCurve></CustomCurve>
          <SubTextContainer>
            <CustomSubText>
              Discover your passion and expand your knowledge with our diverse
              range of courses across various categories
            </CustomSubText>
          </SubTextContainer>
          <SubSmallTextContainer>
            <CustomSmallSubText>
              Choose a <span style={{ color: "#504298" }}>course</span> that
              sparks your kid's future
            </CustomSmallSubText>
          </SubSmallTextContainer>
        </CategoryTextContainer>
        {/* Mobile Top Button */}
        {/* <MobileTopButtonContainer>
          <MobileTopButton>KG - Grade 3</MobileTopButton>
          <MobileTopButton>Grade 4-8</MobileTopButton>
          <MobileTopButton>Grade 4-8</MobileTopButton>
        </MobileTopButtonContainer> */}
        {/* <TopButtonContainer>
          <TopButton>KG - Grade 3</TopButton>
          <TopButton>Grade 4-8</TopButton>
          <TopButton>Grade 4-8</TopButton>
        </TopButtonContainer> */}
      </CategoryContainer>
    </>
  );
};

export default ExploreCategory;
