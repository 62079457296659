import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import {
  AppbarContainer,
  AppbarHeader,
  CustomMenuIcon,
  LinkText,
  MylistContainer,
  TopbarButton,
  TopbarButtonContainer,
  TopbarRegister,
  AppbarButtonLinksContainer,
  SideLogoImageContainer,
  // DropDown,
  // IconBtn,
  // NavbarDivider,
  ModalDrawer,
  MobileSideLogoImageContainer,
  MobileMainLinksContainer,
  CustomAvatar,
  MobileMainLogoCrossContainer,
  CloseSideBarContainer,
} from "../../../styles/GlobalsStyling/appbar/appbarStyle";
import SignUp from "../../AuthenticationComponents/signup/SignUp";
import SignIn from "../../AuthenticationComponents/signin/SignIn";
import MobileSignUp from "../../AuthenticationComponents/signup/MobileSignUp";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavbar } from "../../../customhooks/Navbar/useNavbar";
import CloseIcon from "@mui/icons-material/Close";
import { logOutUser } from "../../../customhooks/Auth/useAuth";
import MobileSidebar from "../mobilesidebar/MobileSidebar";
import { NavbarLinks } from "../../../dummydata/DummyData";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const Navbar = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 540);
  const {
    isDrawerOpen,
    signInModal,
    signupModel,
    handleSignInModal,
    setSignInModal,
    setSignupModel,
    handleSignupModal,
    handleDrawerOpen,
    handleDrawerClose,
  } = useNavbar();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const justifyContent = isSmallScreen ? "flex-end" : "space-between";
  const userDataJSON = Cookies.get("userData");
  const Token = Cookies.get("token");
  const parsedUserData = userDataJSON ? JSON.parse(userDataJSON) : null;

  const handleLogout = () => {
    logOutUser(dispatch);
    navigate("/");
  };
  return (
    <>
      <AppbarContainer>
        <Grid>
          <CustomMenuIcon onClick={handleDrawerOpen} />
          {isDrawerOpen && (
            <ModalDrawer
              anchor="left"
              open={isDrawerOpen}
              onClose={handleDrawerClose}
            >
              <MobileMainLinksContainer>
                <MobileMainLogoCrossContainer>
                  <MobileSideLogoImageContainer></MobileSideLogoImageContainer>
                  <CloseSideBarContainer onClick={handleDrawerClose}>
                    <CloseIcon
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "var(--clr-secondary)",
                      }}
                    />
                  </CloseSideBarContainer>
                </MobileMainLogoCrossContainer>
                <MobileSidebar />
              </MobileMainLinksContainer>
            </ModalDrawer>
          )}
        </Grid>
        <Grid container>
          {/* Row 1 */}
          <Grid container justifyContent={justifyContent} alignItems="center">
            <Grid item>
              <AppbarHeader>
                <SideLogoImageContainer></SideLogoImageContainer>
              </AppbarHeader>
            </Grid>
            <Grid item>
              <AppbarButtonLinksContainer>
                <MylistContainer>
                  {NavbarLinks.map((bigscreenlinks) => (
                    <LinkText
                      to={bigscreenlinks?.path}
                      key={bigscreenlinks.id}
                      target={
                        bigscreenlinks.name === "VR Labs" ? "_blank" : undefined
                      }
                      rel={
                        bigscreenlinks.name === "VR Labs"
                          ? "noopener noreferrer"
                          : undefined
                      }
                    >
                      {bigscreenlinks?.name}
                    </LinkText>
                  ))}
                </MylistContainer>
                <Grid item>
                  <TopbarButtonContainer>
                    {Token ? (
                      <>
                        <Stack direction="row" spacing={2}>
                          <CustomAvatar />
                          <p
                            style={{
                              color: "var(--clr-secondary)",
                              marginTop: "5px",
                            }}
                          >
                            {parsedUserData?.firstName}{" "}
                            {parsedUserData?.lastName}{" "}
                          </p>
                          <button onClick={handleLogout}>logout</button>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <TopbarButton onClick={handleSignInModal}>
                          Login
                        </TopbarButton>
                        <TopbarRegister onClick={handleSignupModal}>
                          Sign Up
                        </TopbarRegister>
                      </>
                    )}
                  </TopbarButtonContainer>
                </Grid>
              </AppbarButtonLinksContainer>
            </Grid>
            <MobileSignUp />
            {
              <SignUp
                open={signupModel}
                onClose={() => setSignupModel(false)}
              />
            }
            {
              <SignIn
                open={signInModal}
                onClose={() => setSignInModal(false)}
              />
            }
          </Grid>
        </Grid>
      </AppbarContainer>
    </>
  );
});

export default Navbar;
