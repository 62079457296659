import React from "react";
import {
  ProductHeroMainContainer,
  ProductImageContainer,
  ProductLeftButtonContainer,
  ProductLeftSide,
  ProductLeftSideContainer,
  ProductLeftSideMainHeding,
  ProductLeftSideSubHeding,
  ProductPlayStoreButton,
} from "../../../styles/ProductStyling/herosection/styleProductHero";
import PlayStore from "../../../assets/images/playstore.png";
import AppStore from "../../../assets/images/appstore.png";
import { useGetProductTopBanner } from "../../../customhooks/ourproducts/useProduct";
import { CircularProgress } from "@mui/material";
const ProductHero = () => {
  const { TopBannerData, isLoading, isSuccess } = useGetProductTopBanner();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <ProductHeroMainContainer>
        {TopBannerData?.map((TopData) => (
          <React.Fragment key={TopData?.TopBannerId}>
            <ProductLeftSide>
              <ProductLeftSideContainer>
                <ProductLeftSideMainHeding>
                  Our Product
                </ProductLeftSideMainHeding>
                <ProductLeftSideSubHeding>
                  {TopData?.TopBannerDescription}
                </ProductLeftSideSubHeding>
                <ProductLeftButtonContainer>
                  <ProductPlayStoreButton>
                    <img
                      src={PlayStore}
                      alt="playStore"
                      style={{ height: "100%", objectFit: "cover" }}
                    />
                  </ProductPlayStoreButton>
                  <ProductPlayStoreButton>
                    <img
                      src={AppStore}
                      alt="appStore"
                      style={{ height: "100%", objectFit: "cover" }}
                    />
                  </ProductPlayStoreButton>
                </ProductLeftButtonContainer>
              </ProductLeftSideContainer>
            </ProductLeftSide>
            <ProductImageContainer>
              <img
                src={TopData?.TopBannerImage}
                alt="Steam Right"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </ProductImageContainer>
          </React.Fragment>
        ))}
      </ProductHeroMainContainer>
    );
  }
};

export default ProductHero;
