import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ContactApi = createApi({
  reducerPath: "contactApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    contactNow: builder.mutation({
      query: (data) => ({
        url: "contactus",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useContactNowMutation } = ContactApi;
