import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import GoogleMapImage from "../../../../src/assets/images/googlemap.png";

export const LearningMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",

  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const LearningTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const LearningMainHeading = styled(Typography)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  textAlign: "center",
  lineHeight: "44px",
  color: "#213D39",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
    lineHeight: "28px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const CustomGoogleMapImage = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   marginRight: "14px",
  marginLeft: "40px",
  marginTop: "-12px",
  width: "700px",
  height: "500px",
  backgroundImage: `url(${GoogleMapImage})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 820px)": {
    width: "600px",
  },
  "@media (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 440px)": {
    width: "300px",
  },
}));
