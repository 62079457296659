import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import Logo from "../../../../src/assets/images/logo.png";
export const AppbarContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  background: "#FFFFFF",
  padding: "0px 20px",
  height: "88px",
  filter: "drop-shadow(0px 0px 10px rgba(80, 66, 152, 0.26))",
  "@media (max-width: 820px)": {
    padding: "0px 20px",
  },
  "@media (max-width: 540px)": {
    padding: "0px 10px",
    justifyContent: "space-between",
  },
}));
export const CustomGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "flex-end",
  },
}));
export const AppbarHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

// Header
export const AppbarHeader = styled(Box)(() => ({
  marginLeft: "8px",
  padding: "4px",
  flexGrow: 1,
  width: "72px",
  height: "85px",
  "@media (max-width: 768px)": {
    marginTop: "4px",
  },
  "@media (max-width: 540px)": {
    marginLeft: "0px",
  },
}));

export const SideLogoImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px",
  width: "70px",
  height: "60px",
  borderRadius: "7px",
  backgroundImage: `url(${Logo})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 540px)": {
    marginLeft: "0px",
  },
}));

export const AppbarButtonLinksContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
//Topbar Link Container
export const MylistContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "20px 0px 20px 0px",

  "@media (max-width: 1012px)": {
    display: "none",
  },
}));
export const DropDown = styled("p")(() => ({
  textDecoration: "none",
  cursor: "pointer",
  marginRight: "32px",
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  letterSpacing: "0.032em",
  lineHeight: "18px",
  color: "#000000",
}));

export const IconBtn = styled(IconButton)(({ theme }) => ({
  marginLeft: "-6px",
  marginRight: "-7px",
  "&:hover": {
    background: "none",
  },
}));
export const NavbarDivider = styled(Divider)(({ theme }) => ({
  marginTop: "5px",
}));
// Topbar Links
export const LinkText = styled(NavLink)(() => ({
  textDecoration: "none",
  cursor: "pointer",
  marginRight: "32px",
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  letterSpacing: "0.032em",
  lineHeight: "18px",
  color: "#000000",
  "&.active": {
    color: "#504298",
    borderBottom: "2px solid #504298",
    paddingBottom: "8px",
    marginTop: "9px",
    fontWeight: "bold",
  },
  "@media (max-width: 1050px)": {
    marginRight: "15px",
    letterSpacing: "0.013em",
  },
}));

//Top Button Container
export const TopbarButtonContainer = styled(Box)(() => ({
  display: "flex",
  padding: "0px 0px, 0px, 0px",
  marginTop: "2px",
  marginLeft: "30px",

  "@media (max-width: 540px)": {
    display: "none",
  },
}));
//Topbar Button
export const TopbarButton = styled(Button)(() => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  padding: "5px 20px",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#504298",
  textTransform: "capitalize",
  marginRight: "12px",
  boxShadow: "0px 4px 24px rgba(80, 66, 152, 0.1)",
  borderRadius: "80px",
  "&:hover": {
    backgroundColor: "transparent",
  },

  "@media (max-width: 768px)": {
    fontSize: "14px",
    lineHeight: "20px",
  },

  "@media (max-width: 568px)": {
    fontSize: "12px",
    lineHeight: "18px",
    marginRight: "-4px",
  },
}));
//Topbar Button Register
export const TopbarRegister = styled(Button)(() => ({
  fontFamily: "Roboto",
  borderRadius: "32px",
  padding: "0px 20px",
  color: "white",
  textTransform: "capitalize",
  fontSize: "14px",
  // marginRight: "14px",
  background: "linear-gradient(0deg, #504298, #504298), #00B8D9",
  boxShadow:
    "0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)",

  "@media (max-width: 768px)": {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "8px",
  },

  "@media (max-width: 568px)": {
    fontSize: "10px",
    lineHeight: "18px",
    padding: "3px",
    marginBottom: "4px",
  },
}));

//Topbar Menu Icon
export const CustomMenuIcon = styled(MenuIcon)(() => ({
  marginTop: "24px",
  marginRight: "5px",
  color: "var(--clr-secondary)",
  cursor: "pointer",
  fontSize: "40px",
  display: "none",
  "@media (max-width: 1012px)": {
    display: "block",
  },
}));

// Mobile Links

export const ModalDrawer = styled(Drawer)(() => ({
  height: "70%",
}));

export const MobileMainLinksContainer = styled(Box)(() => ({
  width: "80vw",
  padding: "20px 25px",
}));

export const MobileMainLogoCrossContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const MobileSideLogoImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px",
  width: "50px",
  height: "50px",
  borderRadius: "7px",
  backgroundImage: `url(${Logo})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 540px)": {
    marginLeft: "0px",
  },
}));
export const CloseSideBarContainer = styled(Box)(() => ({
  width: "30px",
  height: "30px",
}));
export const CustomAvatar = styled(Avatar)(() => ({
  width: "30px",
  height: "30px",
}));
