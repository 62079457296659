import React from "react";
// Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";
import {
  CustomSwiperSlide,
  MemberDescriptionContainer,
  MemberDesignationContainer,
  MemberNameContainer,
  NameDesignationContainer,
  TeamMemberCarouselContainer,
  TeamMemberImageContainer,
  TeamMemberInfoContainer,
  TopImageRoundCircle,
  TopImageRoundCircleContainer,
  TopImageSection,
} from "../../styles/slider/styleTeamMemberSlider";
import RoundCircle from "../../assets/images/roundcircle.png";
import { useGetTeamMemberData } from "../../customhooks/aboutus/useAboutUs";
import { CircularProgress } from "@mui/material";
const TeamMemberSlider = () => {
  const { TeamMember, isLoading, isSuccess } = useGetTeamMemberData();
  const breakpoints = {
    1500: {
      slidesPerView: 4,
      spaceBetween: 15,
    },
    1240: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    912: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    820: {
      slidesPerView: 2,
    },
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <TeamMemberCarouselContainer>
          <Swiper
            breakpoints={breakpoints}
            spaceBetween={35}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {TeamMember.map((member) => (
              <CustomSwiperSlide key={member.MemberId} style={{}}>
                {/* <SingleSliderContainer style={{ height: "500px" }}> */}
                <TopImageSection>
                  <TopImageRoundCircle>
                    <TopImageRoundCircleContainer>
                      <img
                        src={RoundCircle}
                        alt="round"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <TeamMemberImageContainer>
                        <img
                          src={member?.MemberImage}
                          alt={member?.MemberName}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </TeamMemberImageContainer>
                    </TopImageRoundCircleContainer>
                  </TopImageRoundCircle>
                </TopImageSection>

                <TeamMemberInfoContainer>
                  <NameDesignationContainer>
                    <MemberNameContainer>
                      {member.MemberName}
                    </MemberNameContainer>
                    <MemberDesignationContainer>
                      {member.MemberDesignation}
                    </MemberDesignationContainer>
                  </NameDesignationContainer>
                  <MemberDescriptionContainer>
                    {member.MemberInfo}
                  </MemberDescriptionContainer>
                </TeamMemberInfoContainer>
                {/* </SingleSliderContainer> */}
              </CustomSwiperSlide>
            ))}
          </Swiper>
        </TeamMemberCarouselContainer>
      </>
    );
  }
};

export default TeamMemberSlider;
