import React from "react";
import AboutUsHeroSection from "../../components/AboutUsComponent/aboutusherosection/AboutUsHeroSection";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import MissionVission from "../../components/AboutUsComponent/missionvission/MissionVission";
import WonCompetation from "../../components/AboutUsComponent/competation/WonCompetation";
import TeamMember from "../../components/AboutUsComponent/teammember/TeamMember";
import OurAchievements from "../../components/AboutUsComponent/ourachievements/OurAchievements";
import Footer from "../../components/GlobalComponents/footer/Footer";
const AboutUs = () => {
  return (
    <>
      <Navbar />
      <AboutUsHeroSection />
      <MissionVission />
      <TeamMember />
      <WonCompetation />
      <OurAchievements />
      <Footer />
    </>
  );
};

export default AboutUs;
