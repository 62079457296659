import React from "react";
import {
  // MobileBottomSection,
  // MobileLogoContainer,
  MobileMainContainer,
  // MobileTopSections,
} from "../../../styles/AuthenticationStyling/mobileSignupStyle";

const MobileSignUp = () => {
  return (
    <MobileMainContainer>
      {/* <MobileTopSections>
        <MobileLogoContainer></MobileLogoContainer>
      </MobileTopSections>
      <MobileBottomSection>Naseem</MobileBottomSection> */}
    </MobileMainContainer>
  );
};

export default MobileSignUp;
