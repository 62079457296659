import React from "react";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import ProductHero from "../../components/OurProductComponent/herosection/ProductHero";
import ProductSection from "../../components/OurProductComponent/productsection/ProductSection";
import Footer from "../../components/GlobalComponents/footer/Footer";

const Product = () => {
  return (
    <>
      <Navbar />
      <ProductHero />
      <ProductSection />
      <Footer />
    </>
  );
};

export default Product;
