import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const CompetationCarouselContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 0px",
  width: "100%",
  height: "100%",
}));

export const SliderMainContainer = styled(Box)(() => ({
  borderRadius: "18px",
  backgroundColor: "var(--clr-secondary)",
  margin: "20px 20px 50px 20px",
  border: "1px solid #EFF0F6",
  boxShadow: "0px 5px 14px 0px rgba(8, 15, 52, 0.04)",
}));
export const SliderImageTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "0px",
  padding: "12px",
  width: "100%",
  height: "50vh",
}));

export const SliderLeftSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "75%",
  margin: "0px",
  padding: "12px",
}));

export const SliderTopSection = styled(Box)(() => ({
  display: "flex",
  margin: "0px",
  padding: "0px 12px",
  fontFamily: "cursive",
  color: "var(--clr-white)",
}));
export const SliderMainHeadSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "-20px 0px",
  padding: "0px 12px",
  fontFamily: "Poppins",
  fontWeight: 700,
  fontSize: "75px",
  color: "var(--clr-white)",
  "@media (max-width: 540px)": {
    fontSize: "35px",
    margin: "0px 0px",
  },
}));

export const SliderSubDescriptionSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "0px",
  padding: "30px 0px 0px 25px",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "14px",
  color: "var(--clr-white)",
  "@media (max-width: 540px)": {
    fontSize: "13px",
    padding: "10px",
  },
}));

export const SliderRightSection = styled(Box)(() => ({
  display: "flex",
  margin: "0px",
  padding: "12px",
  objectFit: "cover",
  width: "20%",
}));
