import React from "react";
import {
  AllBlogsMainContainer,
  BlogDate,
  BlogTitle,
  BlogUpperDivison,
  BlogsCardContainer,
  BlogsCardImage,
  BlogsCustomCard,
  ReadMoreArrow,
  ReadMoreButton,
  ReadMoreButtonContainer,
} from "../../../styles/BlogsStyling/allblogsstyle/styleAllBlogs";
import Navbar from "../../GlobalComponents/appbar/Navbar";

import { BlogsData } from "../../../dummydata/DummyData";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const AllBlogs = () => {
  const navigate = useNavigate();
  const handleBlogCardClick = (id) => {
    navigate(`/blog-details/${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar />

      <AllBlogsMainContainer>
        <h4
          style={{
            fontFamily: "Poppins",
            fontSize: "44px",
            lineHeight: "50px",
          }}
        >
          STEAM BLOGS
        </h4>
        <BlogsCardContainer>
          {BlogsData.map((blogData) => (
            <BlogsCustomCard
              onClick={() => handleBlogCardClick(blogData?.id)}
              key={blogData.id}
            >
              <BlogsCardImage>
                <img
                  src={blogData?.img}
                  alt={blogData?.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <BlogUpperDivison>
                  <BlogTitle>{blogData?.title}</BlogTitle>
                  <BlogDate>{blogData?.Date}</BlogDate>
                  {/* <Divider style={{ color: "#fff" }} /> */}
                  {/* <CustomBlogDivider /> */}
                  <ReadMoreButtonContainer>
                    <ReadMoreButton>Read More</ReadMoreButton>
                    <ReadMoreArrow>
                      <ArrowForwardIcon />
                    </ReadMoreArrow>
                  </ReadMoreButtonContainer>
                </BlogUpperDivison>
              </BlogsCardImage>
            </BlogsCustomCard>
          ))}
        </BlogsCardContainer>
      </AllBlogsMainContainer>
    </>
  );
};

export default AllBlogs;
