import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import ContactUs from "./pages/contactus/ContactUs";
import Courses from "./pages/explorecourses/Courses";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleCourse from "./components/ExploreCoursesComponents/singlecoursedetail/singlecourse/SingleCourse";
import SteamAmbassadors from "./pages/steamambassadors/SteamAmbassadors";
import AboutUs from "./pages/aboutus/AboutUs";
import CoursePayment from "./components/ExploreCoursesComponents/proceedtopay/CoursePayment";
import CourseVideo from "./components/ExploreCoursesComponents/singlecoursedetail/singlecoursedetailinfo/coursevideo/CourseVideo";
import AllBlogs from "./components/BlogsComponent/allblogs/AllBlogs";
import SingleBlog from "./components/BlogsComponent/singleblog/SingleBlog";
import SteamMIndsVRLabs from "./pages/vrlabs/SteamMIndsVRLabs";
import Product from "./pages/product/Product";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import CourseForm from "./pages/addcourseform";
function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/vr-labs" element={<SteamMIndsVRLabs />} />
          <Route path="/blog-details/:id" element={<SingleBlog />} />
          <Route path="/explore-allcourses" element={<Courses />} />
          <Route path="/course-details/:id" element={<SingleCourse />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/video/:id" element={<CourseVideo />} />
          </Route>
          <Route path="/enroll-now/:id" element={<CoursePayment />} />
          <Route path="/steam-ambassadors" element={<SteamAmbassadors />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/steam-products" element={<Product />} />
          <Route path="/add" element={<CourseForm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
