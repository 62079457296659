import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const CourseLessonVideoApi = createApi({
  reducerPath: "CourseLessonVideoApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    // getCourse: builder.query({
    //   query: () => ({
    //     url: "course",
    //     method: "GET",
    //   }),
    // }),
    getLessonVideoByLessonId: builder.query({
      query: (id) => ({
        url: `lessonvideo/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLessonVideoByLessonIdQuery } = CourseLessonVideoApi;
