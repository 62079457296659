import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Navigation } from "swiper";

import {
  CompetationCarouselContainer,
  SliderImageTextContainer,
  SliderLeftSection,
  SliderTopSection,
  SliderMainContainer,
  SliderMainHeadSection,
  SliderSubDescriptionSection,
  SliderRightSection,
} from "../../styles/slider/styleWiningCompetationSlider";
import WinAwardImage from "../../../src/assets/images/winawardimage.png";
import { useGetCompetationData } from "../../customhooks/aboutus/useAboutUs";
import { CircularProgress } from "@mui/material";
const WiningCompetationSlider = () => {
  const { Competation, isLoading, isSuccess } = useGetCompetationData();
  const breakpoints = {
    1500: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    912: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    820: {
      slidesPerView: 2,
    },
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <CompetationCarouselContainer>
          <Swiper
            breakpoints={breakpoints}
            spaceBetween={35}
            freeMode={true}
            rewind={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="mySwiper"
          >
            {Competation.map((item) => (
              <SwiperSlide key={item.CompetationId}>
                <SliderMainContainer>
                  <SliderImageTextContainer>
                    <SliderLeftSection>
                      <SliderTopSection>STEAM Minds</SliderTopSection>
                      <SliderMainHeadSection>WINNER</SliderMainHeadSection>
                      <SliderSubDescriptionSection>
                        {item?.CompetationDescription}
                      </SliderSubDescriptionSection>
                    </SliderLeftSection>
                    <SliderRightSection>
                      <img
                        src={WinAwardImage}
                        alt="steam award winner"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </SliderRightSection>
                  </SliderImageTextContainer>
                </SliderMainContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </CompetationCarouselContainer>
      </>
    );
  }
};

export default WiningCompetationSlider;
