import { useMemo } from "react";
import { useGetHomeTestimonialsQuery } from "../../../features/home/hometestimonials/homeTestimonialsSlice";

export const useGetHomeTestimonials = () => {
  const breakPoints = {
    1024: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    912: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    },

    540: {
      slidesPerView: 1,
    },
  };
  const { data, isSuccess, isLoading } = useGetHomeTestimonialsQuery();
  const HomeTestimonialsInfo = useMemo(() => {
    const getHomeTestimonials = data || [];

    return getHomeTestimonials?.map((testimonial) => ({
      testimonialId: testimonial?._id,
      studentName: testimonial?.name,
      courseName: testimonial?.coursename,
      studentImage: testimonial?.userpic,
      studentReview: testimonial?.content,
    }));
  }, [data]);
  return {
    HomeTestimonials: HomeTestimonialsInfo,
    breakpoints: breakPoints,
    isLoading,
    isSuccess,
  };
};
