import React from "react";

import {
  CategoryName,
  CustomLeftImage,
  NameCategoryContainer,
  SliderContainer,
  SliderTopSection,
  StudentCarouselContainer,
  StudentName,
  StudentReviewDescription,
} from "../../styles/slider/styleStudentSlider";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import { FreeMode, Pagination, Navigation } from "swiper/core";
// import required modules
import { FreeMode, Pagination, Navigation } from "swiper";
import { Divider } from "@mui/material";
import { useGetHomeTestimonials } from "../../customhooks/homeCustomHooks/hometestimonials/useHomeTestimonials";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomLeftImageContainer } from "../../styles/ExploreCourseStyling/singlecoursetestimonials/styleCourseTestimonials";
const StudentReviewSlider = () => {
  const { HomeTestimonials, isLoading, isSuccess, breakpoints } =
    useGetHomeTestimonials();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <StudentCarouselContainer>
          <Swiper
            breakpoints={breakpoints}
            spaceBetween={35}
            freeMode={true}
            rewind={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="mySwiper"
          >
            {HomeTestimonials.map((studenttestimonial) => (
              <SwiperSlide key={studenttestimonial.testimonialId}>
                <SliderContainer>
                  <SliderTopSection>
                    <CustomLeftImageContainer>
                      <CustomLeftImage src={studenttestimonial?.studentImage} />
                    </CustomLeftImageContainer>
                    <NameCategoryContainer>
                      <StudentName>
                        {studenttestimonial?.studentName}
                      </StudentName>
                      <CategoryName>
                        {studenttestimonial?.courseName}
                      </CategoryName>
                    </NameCategoryContainer>
                  </SliderTopSection>
                  <Divider />
                  <StudentReviewDescription>
                    {studenttestimonial.studentReview}
                  </StudentReviewDescription>
                </SliderContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </StudentCarouselContainer>
      </>
    );
  }
};

export default StudentReviewSlider;
