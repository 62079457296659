import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const SingleBlogMainContainer = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  flexDirection: "column",
  margin: "30px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const TitleButtonContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  // margin: "30px 60px",
}));

export const SingleBlogTitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  // margin: "30px 60px",
}));

export const SingleBlogTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: "Poppins",
  fontSize: "50px",
  fontWeight: 400,
  color: "var(--clr-card-text)",
  // margin: "30px 60px",
}));

export const SingleBlogButtonContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-end",

  justifyContent: "flex-end",
  // margin: "30px 60px",
}));

export const SingleBlogButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  padding: "8px",
  // width: "120px",
  background: "var(--clr-secondary)",
  borderRadius: "4px",
  color: "var(--clr-white)",
  fontFamily: "Poppins",
  fontSize: "14px",
  // margin: "30px 60px",
}));

export const SingleBlogImageContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: "40px",
  width: "100%",
  height: "420px",
}));

export const SubTitleDateContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "40px",
  width: "100%",
}));

export const SingleBlogSubTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  color: "#979797",
  fontSize: "30px",
  fontFamily: "Poppins",
  fontStyle: "italic",
  fontWeight: "400",
  lineHeight: "46.50px",
}));

export const SingleBlogDateInfo = styled(Box)(() => ({
  display: "flex",
  width: "140px",
  padding: "5px",
  background: "var(--clr-secondary)",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "14px",
  justifyContent: "center",
  color: "var(--clr-white)",
  alignItems: "center",
}));

export const BlogContentContainer = styled(Box)(() => ({
  marginTop: "40px",
  // display: "flex",
  alignItems: "center",
  textAlign: "justify",
  fontFamily: "lato",
  fontSize: "18px",
  lineHeight: "27.9px",
  color: "var(--clr-card-text)",
}));
